import React from "react"
import ReactDOM from "react-dom"
import "modern-normalize/modern-normalize.css"
import App from "./App"
import { define as defineImgWithFallback } from "~/webcomponents/img-with-fallback"
import { define as defineQuillEditor } from "~/webcomponents/quill-editor"
import { define as definePopper } from "~/webcomponents/popper"
import { define as defineModalDialog } from "~/webcomponents/modal-dialog"
import { define as defineTutorialLink } from "~/webcomponents/tutorial-link"
import "@github/clipboard-copy-element"


defineImgWithFallback()
defineQuillEditor()
definePopper()
defineModalDialog()
defineTutorialLink()

const render = () => <App />

ReactDOM.render(render(), document.getElementById("root"))
