import { PropType } from "~/lib/TypeHelpers"
import { UUID } from "~/store/types"
import { ColumnType } from "~/components/advisors/Products/Advice/ProductMapping/Filters/types"

export type ColumnMapping = PropType<DataSource, "columnMapping">

enum NumericColumnMappingType {
  GREATER_THAN = "GREATER_THAN",
  SMALLER_THAN = "SMALLER_THAN",
  EQUALS = "EQUALS",
  BANDWIDTH = "BANDWIDTH",
}

enum BandwidthOrientation {
  START = "START",
  AROUND = "AROUND",
  END = "END",
}

export type NumericColumnMapping =
  | {
      column: string
      type:
        | NumericColumnMappingType.GREATER_THAN
        | NumericColumnMappingType.SMALLER_THAN
        | NumericColumnMappingType.EQUALS
      bandwidth: null
      orientation: null
    }
  | {
      column: string
      type: NumericColumnMappingType.BANDWIDTH
      bandwidth: number
      orientation: BandwidthOrientation
    }

export type Product = {
  id: UUID
  dataSourceId: UUID
  productId: string
  data: Record<string, string[]>
  valid: boolean
}

type TypeMappingFormat = {
  DECIMALSEPARATOR?: string
  CURRENCY?: Currency
}

export type DataSource = {
  id: UUID
  advisorId: UUID
  name: string
  type: "CSV" | "GOOGLE_SHOPPING_FEED"
  config: Record<string, any> | null
  typeMapping: Record<
    string,
    { dataType: ColumnType; format: TypeMappingFormat }
  >
  columnMapping: {
    image: string | undefined
    name: string | undefined
    id: string | undefined
    url: string | undefined
    price: string | undefined
  }
  meta?: {
    fileName?: string
  }
  updatedAt: number
}

export enum ValidationCode {
  TITLE_LENGTH = "TITLE_LENGTH",
  ANSWER_COUNT = "ANSWER_COUNT",
  MISSING_REFERENCE = "MISSING_REFERENCE",
  MISSING_ANSWER = "MISSING_ANSWER",
  MISSING_QUESTION = "MISSING_QUESTION",
  DEFAULT_STYLES = "DEFAULT_STYLES",
  CLASSIFICATION_INCOMPLETE = "CLASSIFICATION_INCOMPLETE",
  CLASSIFICATION_EMPTY = "CLASSIFICATION_EMPTY",
  NO_PRODUCTS_FOUND = "NO_PRODUCTS_FOUND",
  NO_QUESTIONS = "NO_QUESTIONS",
  MISSING_SECONDARY_SORT_COLUMN = "MISSING_SECONDARY_SORT_COLUMN",
  FLOW_CYCLE = "FLOW_CYCLE",
  DRILLDOWN_ANSWER_COUNT = "DRILLDOWN_ANSWER_COUNT",
  ADVICE_SCREEN_UNREACHABLE = "ADVICE_SCREEN_UNREACHABLE",
}

export enum NoticeType {
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export type Notice = {
  type: NoticeType
  code: ValidationCode
  meta: {
    questionId?: string
    ruleId?: string
    answerId?: string
    screenId?: string
  }
}

export type AdvisorValidationResult = {
  advice: Notice[]
  questions: Notice[]
  media: Notice[]
  answers: Notice[]
  rules: Notice[]
  screens: Notice[]
  lookAndFeel: Notice[]
  catalogue: Notice[]
}

export type NotificationT = {
  id: string
  text: string
  action?: { text: string; onClick: () => void }
  onDismiss?: () => void
  type: "info" | "warning" | "error" | "success"
}

export enum Currency {
  USD = "USD",
  EUR = "EUR",
  JPY = "JPY",
  GBP = "GBP",
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  FJD = "FJD",
  FKP = "FKP",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HNL = "HNL",
  HKD = "HKD",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KID = "KID",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PRB = "PRB",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SLS = "SLS",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STN = "STN",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TVD = "TVD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  UYU = "UYU",
  UZS = "UZS",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XCD = "XCD",
  XOF = "XOF",
  XPF = "XPF",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWB = "ZWB",
}

export type PreviewMode = "banner" | "inpage" | "modal" | "sidebar"

export interface PreviewModeStorage {
  [key: string]: string
}
