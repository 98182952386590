import { useTranslation } from "react-i18next"

export const IssuesTitle: React.FC<{ issueCount: number }> = ({
  issueCount,
}) => {
  const { t } = useTranslation()
  return (
    <p>
      <strong>
        {t("productDataset.feed.setup.feedback.issuesTitle", {
          count: issueCount,
        })}
      </strong>
    </p>
  )
}
