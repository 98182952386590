import { changeNext, updateIntroScreenData } from "~/actions/screens/actions"
import Field from "~/components/forms/Field"
import FieldTextArea from "~/components/forms/FieldTextArea"
import HelpText from "~/components/HelpText"
import MarkdownTextarea from "~/components/MarkdownTextarea"
import SelectionUI from "~/components/selection/SelectionUI"
import ToggleSwitch from "~/components/ToggleSwitch"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { Advisor } from "~/store/advisors"
import { IntroScreen } from "~/store/screens"
import styled from "styled-components"
import useNavigation from "~/hooks/useNavigation"
import { getQuestions, QuestionColor } from "~/store/questions"
import { ApplicationState } from "~/store"
import TabNav from "~/components/TabNav"
import { getCurrentColor } from "~/themes"
import QuestionLabel from "~/components/QuestionLabel"
import DarkSelect from "~/components/selection/DarkSelect"
import Add from "~/components/icons/Add"
import useQuestions from "~/hooks/useQuestions"
import { addAfter, Flow } from "~/actions/flow"
import Emoji from "~/components/Emoji"
import { v4 as uuid } from "uuid"

const Section = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const ExtraFieldContainer = styled.div`
  display: flex;
  width: 100%;

  .btn {
    margin-left: 1em;
  }

  + .help-text {
    margin-bottom: 2em;
  }

  ${SelectionUI.DropdownWrapper} {
    margin: 0 0 0 1em;
    flex: 1;
    min-width: 0;
  }

  ${SelectionUI.SelectedValue} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;

    &:before {
      content: "";
      width: 2.25rem;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: white;
    }
  }
`

const ExtraFieldAdditions = styled.div`
  + .help-text {
    margin-bottom: 2em;
  }
`

const AdviceScreenEditor: React.FC<{
  advisor: Advisor
  screen: IntroScreen
}> = ({ advisor, screen }) => {
  const { url } = useRouteMatch()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { map } = useNavigation()
  const updateIntro = (data: IntroScreen) =>
    dispatch(updateIntroScreenData(advisor.id, data))
  const changeNextOfIntro = (scr: IntroScreen, next: string) =>
    dispatch(changeNext("START", next, { advisorId: advisor.id }))
  const advisorId = advisor.id

  const { addNewQuestion, questions } = useQuestions(advisorId)
  const { toQuestionInAdvisor, toScreenInAdvisor } = useNavigation()
  const infoScreens = Flow.useInfoScreens(advisorId)

  const nextOptions = useSelector((state: ApplicationState) => {
    const questionIds = state.advisors[advisor.id]?.questions || []
    const infoScreens = state.screens[advisor.id]?.infoScreens || []
    const questions = getQuestions(state, questionIds)
    return [
      ...questions.map((q) => ({ label: q.label, value: q.id })),
      ...infoScreens.map((scr) => ({ label: scr.label, value: scr.id })),
    ]
  })

  const nextOfScreen = useSelector((state: ApplicationState) => {
    return state.flow[advisorId]?.nodes["START"]
  })
  const inlineStyle = { margin: "0 .35rem" }

  const optionsWithNewQuestion = (
    options: { value: string; label: string }[]
  ) => [
    ...nextOptions,
    {
      value: "ADVICE",
      label: (
        <>
          <Emoji emoji=":point_right:" size={16} /> {t("advise")}
        </>
      ),
    },
    {
      value: "NEW_QUESTION",
      label: (
        <>
          <Add />
          {t("rulesEditor.newQuestion")}
        </>
      ),
    },
    {
      value: "NEW_INFO_PAGE",
      label: (
        <>
          <Add />
          {t("rulesEditor.newInfoPage")}
        </>
      ),
    },
  ]
  const options = optionsWithNewQuestion([
    ...questions
      .filter((a) => a.id !== screen.id)
      .map((q) => ({ value: q.id, label: q.label })),
    ...infoScreens
      .filter((a) => a.id !== screen.id)
      .map((q) => ({ value: q.id, label: q.label })),
  ])

  const nodes = useSelector((state: ApplicationState) => {
    return state.flow[advisor.id]?.nodes
  })

  return (
    <>
      <Section>
        <TabNav
          withUnderLineBorder={true}
          exact={true}
          options={[
            {
              label: (
                <>
                  <span>Content</span>
                </>
              ),
              link: `${url}`,
            },

            {
              label: (
                <>
                  <span>Flow</span>
                </>
              ),
              link: `${url}/rules`,
            },
          ]}
        />
      </Section>
      <Switch>
        <Route
          path={`${url}/rules`}
          exact
          render={({ match }) => (
            <div className="form-field">
              <>
                <div>
                  {t("infoScreenRulesEditor.base.start")}
                  <QuestionLabel
                    col={getCurrentColor(QuestionColor.Color1)}
                    style={inlineStyle}
                  >
                    {"Start"}
                  </QuestionLabel>
                  {t("infoScreenRulesEditor.base.end")}
                  <span style={inlineStyle}>
                    <DarkSelect
                      inline
                      small
                      value={nextOfScreen}
                      emptyText={t("rulesEditor.selectQuestion")}
                      options={options}
                      position="bottom-start"
                      onSelect={(value) => {
                        if (value === "NEW_QUESTION") {
                          const id = uuid()
                          addNewQuestion({ id, next: nextOfScreen })
                          changeNextOfIntro(screen, id)
                          toQuestionInAdvisor(advisorId, id)
                        } else if (value === "NEW_INFO_PAGE") {
                          const id = uuid()
                          dispatch(
                            addAfter(
                              {
                                id: id,
                                type: "infoScreen",
                                next: nextOfScreen || "ADVICE",
                              },
                              "INTRO",
                              { advisorId }
                            )
                          )
                          changeNextOfIntro(screen, id)
                          toScreenInAdvisor(advisorId, id)
                        } else {
                          dispatch(changeNextOfIntro(screen, value))
                        }
                      }}
                    />
                  </span>
                </div>
              </>
            </div>
          )}
        />
        <Route
          path={`${url}`}
          exact
          render={() => (
            <>
              <p>
                <Trans
                  i18nKey={"screenEditor.introIntro"}
                  values={{
                    link: t("advisor.navigation.lookAndFeel.title"),
                  }}
                  components={[
                    <Link to={map.lookAndFeel(advisor.id)} className="link">
                      Label
                    </Link>,
                  ]}
                />
              </p>
              <div>
                <ToggleSwitch
                  toggledOn={!screen.skipIntro}
                  label={<strong>{t("screenEditor.fields.skipIntro")}</strong>}
                  onChange={(value) =>
                    updateIntro({ ...screen, skipIntro: !value })
                  }
                />
                <HelpText>{t("screenEditor.fields.skipIntroDesc")}</HelpText>
              </div>
              <FieldTextArea
                label={t("screenEditor.fields.title")}
                value={screen.title}
                maxLength={255}
                onChange={(value) => updateIntro({ ...screen, title: value })}
                disabled={screen.skipIntro}
              />
              <MarkdownTextarea
                label={t("screenEditor.fields.text")}
                isOptional={true}
                text={screen.text}
                maxLength={700}
                onChange={(value) => updateIntro({ ...screen, text: value })}
                disabled={screen.skipIntro}
                bounds={"#sidebar"}
                dataTestId={"intro-text"}
              />
              <MarkdownTextarea
                label={t("screenEditor.fields.additionalInformation")}
                isOptional={true}
                text={screen.info || ""}
                maxLength={700}
                onChange={(value) => updateIntro({ ...screen, info: value })}
                disabled={screen.skipIntro}
                toolbar={["bold", "link"]}
                bounds={"#sidebar"}
                dataTestId={"intro-additional"}
                italic
              />
              <Field
                label={t("screenEditor.fields.buttonText")}
                value={screen.buttonText}
                maxLength={64}
                onChange={(value) =>
                  updateIntro({ ...screen, buttonText: value })
                }
                disabled={screen.skipIntro}
              />
            </>
          )}
        />
      </Switch>
    </>
  )
}
export default AdviceScreenEditor
