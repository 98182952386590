import { ApiError } from "~/api/APIBase"
import Button from "~/components/Button"
import ButtonBar from "~/components/ButtonBar"
import Card from "~/components/Card"
import Input from "~/components/forms/Input"
import Label from "~/components/forms/Label"
import LoadingSpinner from "~/components/Loading"
import HelpText from "~/components/HelpText"
import useDatasources from "~/hooks/useDatasources"
import useNavigation from "~/hooks/useNavigation"
import { validUrl } from "~/lib/URLHelpers"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import styled from "styled-components"
import { HowToResolveMessage } from "./HowToResolve"
import { IssuesTitle } from "../IssuesTitle"

const WarningInfoBlock = styled.div`
  background: ${(p) => p.theme.warningBackground};
  border-radius: 0.25rem;
  margin: 1rem 0;
  padding: 1rem;
`

const New: React.FC<{
  advisorId: string
}> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { toDataSource } = useNavigation()
  const { createShoppingFeed } = useDatasources(props.advisorId)
  const [feedConfig, setFeedConfig] = React.useState({ url: "" })
  const [error, setError] = React.useState<Record<string, string[]> | null>(
    null
  )
  const [loading, setLoading] = React.useState(false)

  const onSubmit = () => {
    setError(null)
    setLoading(true)
    createShoppingFeed("Google Shopping feed", feedConfig)
      .then((_datasource) => {
        setLoading(false)
        toDataSource(props.advisorId)
      })
      .catch((error: ApiError) => {
        setLoading(false)
        if (error.data.payload) {
          setError(error.data.payload)
        } else if (error.data.status && error.data.status === "invalid") {
          setError({ url: [error.data.message] })
        } else if (error.data.status && error.data.status) {
          setError({ error: [error.data.message] })
        }
      })
  }

  const isValid = validUrl(feedConfig.url)
  const numProductIdsInError = 10
  const issueCount = error ? Object.keys(error).length : 1

  return (
    <div>
      <h2>{t("productDataset.feed.setup.title")}</h2>
      <Card style={{ maxWidth: "60rem" }}>
        <Label>Google Shopping URL</Label>
        <div>
          <Input
            value={feedConfig.url}
            placeholder={"https://..."}
            hasError={!!error}
            onChange={(e) => {
              setError(null)
              setFeedConfig({ ...feedConfig, url: e.target.value })
            }}
            fullWidth
          />
        </div>
        {error && Array.isArray(error.error) ? (
          <HelpText hasError={true}>{t("errors.error")}</HelpText>
        ) : error && Array.isArray(error.url) ? (
          <HelpText hasError={true}>
            {t("productDataset.feed.setup.feedback." + error.url[0])}
          </HelpText>
        ) : (
          error && (
            <>
              <HelpText hasError={true}>
                {t("productDataset.feed.setup.feedback.multipleErrors")}
              </HelpText>
              <WarningInfoBlock>
                <HowToResolveMessage
                  translationLocation="setup"
                  count={issueCount}
                />
                <IssuesTitle issueCount={issueCount} />

                <ul>
                  {Object.entries(error).map(([k, v], i) => {
                    return (
                      <li key={i} className="issues-list--item">
                        {t("productDataset.feed.setup.feedback.MISSING", {
                          column: k,
                          dataSourceLabel: "field",
                        })}
                        <div>
                          <ProductIds
                            ids={v}
                            showCount={numProductIdsInError}
                          />
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </WarningInfoBlock>
            </>
          )
        )}
        <ButtonBar
          left={<Button onClick={history.goBack}>{t("back")}</Button>}
          right={
            <Button
              onClick={loading ? () => {} : onSubmit}
              primary
              disabled={!isValid}
              style={{ display: "flex", alignItems: "center" }}
            >
              {t("productDataset.feed.setup.actions.import")}
              {loading && (
                <LoadingSpinner size="small" style={{ marginLeft: "0.5em" }} />
              )}
            </Button>
          }
        />
      </Card>
    </div>
  )
}

const ProductId = styled.span`
  background: ${(p) => p.theme.shade2};
  font-family: ${(p) => p.theme.fontMonospace};
  padding: 0.25rem;
  border-radius: 4px;
  margin-bottom: 0.25rem;
  display: inline-block;
  font-size: 0.875em;
`

export const ProductIds: React.FC<{ ids: string[]; showCount: number }> = ({
  ids,
  showCount,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {ids.slice(0, showCount).map((id, i) => {
        return (
          <span key={`${id}-${i}`}>
            {i > 0 && ", "}
            <ProductId> {id} </ProductId>
          </span>
        )
      })}
      {ids.length > showCount &&
        " " +
          t("productDataset.feed.setup.feedback.andMoreProducts", {
            numProducts: ids.length - showCount,
          })}
    </>
  )
}

export default New
