import React from "react"
import { useTranslation } from "react-i18next"
import { Currency } from "~/types"
import { SingleSelect } from "./selection"

const CurrencySelect: React.FC<{
  value: Currency
  onSelect: (value: Currency) => void
}> = (props) => {
  const { t } = useTranslation()

  const options = Object.keys(Currency).map((code) => ({
    label: t("currencies." + code),
    value: code,
  }))

  return (
    <SingleSelect
      onSelect={props.onSelect}
      value={props.value}
      options={options}
      position="bottom-start"
    />
  )
}

export default CurrencySelect
