import { Manager, Reference, Popper } from "react-popper"
import React, { useState, useEffect, createRef, useCallback } from "react"
import styled from "styled-components"
import ChevronCollapse from "./icons/ChevronCollapse"
import ChevronExpand from "./icons/ChevronExpand"

const DropdownWrapper = styled.div<{
  inline?: boolean
}>`
  position: relative;
  display: ${p => (p.inline ? "inline-block" : "block")};
`

const DropdownList = styled.div`
  background: #fff;
  display: inline-block;
  width: 100%;
  min-width: 20rem;
  box-shadow: ${props => props.theme.dp[25]} 0 2px 4px 0;
  border-radius: ${p => p.theme.borderRadius};
  overflow: hidden;
  overflow-y: auto;
  max-height: 30vh;
  z-index: 10;
`

const Option = styled.div`
  white-space: nowrap;
  cursor: pointer;
  padding: 1rem;
  transition: background 150ms ease-in;
  user-select: none;
  &:hover {
    background: ${p => p.theme.shade1};
  }
`

const Selection = styled.div<{ inline?: boolean; small?: boolean }>`
  padding: ${p => (p.small ? "0.35rem" : "0.5rem")};
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${p => p.theme.borderRadius};
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  border: none;
  transition: color 200ms ease-in, background 200ms ease-in;
  font-weight: 600;
  border: 2px solid ${p => p.theme.shade3};
  &:focus {
    background: ${props => props.theme.shade1};
  }
  position: relative;
`

type DropdownProps = {
  options: { label: React.ReactNode; value: any }[]
  inline?: boolean
  small?: boolean
  marginTop?: string
  marginRight?: string
  marginBottom?: string
  marginLeft?: string
  value: any | any[] | undefined
  currentLabel?: string
  emptyText?: string
  position?:
    | "bottom"
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end"
    | undefined
  onSelect: (value: any) => void
}

const Dropdown: React.FC<DropdownProps> = props => {
  const [open, setOpen] = useState(false)
  const dropdownRef = createRef<HTMLDivElement>()

  const onGlobalClick = useCallback(
    (e: any) => {
      if (dropdownRef?.current?.contains(e.target)) return
      setOpen(false)
    },
    [dropdownRef]
  )

  useEffect(() => {
    if (open) document.addEventListener("click", onGlobalClick)
    return () => document.removeEventListener("click", onGlobalClick)
  }, [onGlobalClick, open])

  useEffect(()  => {
    console.warn("Dropdown is deprecated, use SingeSelect/MultiSelect instead")
  },  [])

  const onSelect = (value: any) => {
    setOpen(false)
    props.onSelect(value)
  }

  const value = Array.isArray(props.value)
    ? {
        label: props.options
          .filter(opt => props.value?.includes(opt.value))
          .map(opt => opt.label)
          .join(","),
        value: props.value,
      }
    : props.options.find(opt => opt.value === props.value)

  return (
    <DropdownWrapper ref={dropdownRef} inline={props.inline}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <Selection
              ref={ref}
              small={props.small}
              onClick={() => setOpen(!open)}
            >
              {value
                ? props.currentLabel
                  ? props.currentLabel
                  : value.label
                : props.emptyText}
              {open ? <ChevronCollapse /> : <ChevronExpand />}
            </Selection>
          )}
        </Reference>
        {open && (
          <Popper placement={props.position || "bottom"}>
            {({ ref, style, placement, arrowProps }) => (
              <DropdownList ref={ref} style={style} data-placement={placement}>
                {props.options.map((opt, i) => (
                  <Option
                    key={`${i}-${opt.value}`}
                    onClick={() => onSelect(opt.value)}
                  >
                    {opt.label}
                  </Option>
                ))}
                <div ref={arrowProps.ref} style={arrowProps.style} />
              </DropdownList>
            )}
          </Popper>
        )}
      </Manager>
    </DropdownWrapper>
  )
}

export default Dropdown
