import React from "react"
import styled from "styled-components"
import ButtonBar from "~/components/ButtonBar"
import Button from "~/components/Button"
import { Valid, Invalid } from "~/components/icons"
import { ColumnMapping, Currency } from "~/types"
import {
  isValidID,
  isValidURL,
  isValidImageURL,
  isValidProductName,
  isValidPrice,
} from "~/lib/ColumnMapping"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import { SingleSelect } from "~/components/selection"
import Label from "~/components/forms/Label"
import CurrencySelect from "~/components/CurrencySelect"

const Option = styled.div`
  /* width: 30%; */
  margin: 2rem 0 1rem 0;
  label {
    display: block;
  }
`

const MapColumns: React.FC<{
  headers: string[]
  data: string[][]
  columnMapping: ColumnMapping
  setColumnMapping: (mapping: ColumnMapping) => void
  priceCurrency: Currency
  setPriceCurrency: (c: Currency) => void
  previous?: () => void
  next?: () => void
}> = ({
  columnMapping,
  setColumnMapping,
  headers,
  next,
  previous,
  children,
  data,
  priceCurrency,
  setPriceCurrency,
}) => {
  const { name, id, image, url } = columnMapping
  const options: { label: string; value: any }[] = headers
    .filter((h) => ![name, id, image, url].includes(h))
    .map((h) => ({ label: h, value: h }))
    .reduce((acc, h) => [...acc, h], [
      { label: "Select column", value: undefined },
    ] as { label: string; value: any }[])

  const { t } = useTranslation()

  const productData = data.slice(0, 100)
  const getIndex = (elem: string) =>
    columnMapping[elem] ? headers.indexOf(columnMapping[elem]) : undefined
  const idIndex = getIndex("id")
  const nameIndex = getIndex("name")
  const urlIndex = getIndex("url")
  const imageIndex = getIndex("image")
  const priceIndex = getIndex("price")

  const rowValid = (index: number | undefined, fn: (str: string) => boolean) =>
    index !== undefined
      ? productData.every((row) => fn(row[index] || ""))
      : undefined

  const validId = rowValid(idIndex, isValidID)
  const validName = rowValid(nameIndex, isValidProductName)
  const validURL = rowValid(urlIndex, isValidURL)
  const validImage = rowValid(imageIndex, isValidImageURL)
  const validPrice =
    priceIndex === undefined || rowValid(priceIndex, isValidPrice)

  const columnsToMap = [
    {
      id: "id",
      label: "id",
      valid: validId,
      validationMessage: t("productDataset.create.step2.validationMessages.id"),
      explanation: t("productDataset.create.step2.explanation.id"),
    },
    {
      id: "name",
      label: t("productDataset.create.step2.labels.name"),
      valid: validName,
      validationMessage: t(
        "productDataset.create.step2.validationMessages.name"
      ),
      explanation: t("productDataset.create.step2.explanation.name"),
    },
    {
      id: "url",
      label: t("productDataset.create.step2.labels.url"),
      valid: validURL,
      validationMessage: t(
        "productDataset.create.step2.validationMessages.url"
      ),
      explanation: t("productDataset.create.step2.explanation.url"),
    },
    {
      id: "image",
      label: t("productDataset.create.step2.labels.image"),
      valid: validImage,
      validationMessage: t(
        "productDataset.create.step2.validationMessages.image"
      ),
      explanation: t("productDataset.create.step2.explanation.image"),
    },
    {
      id: "price",
      label: t("productDataset.create.step2.labels.price"),
      valid: validPrice,
      validationMessage: t(
        "productDataset.create.step2.validationMessages.price"
      ),
      explanation: t("productDataset.create.step2.explanation.price"),
    },
  ]

  return (
    <>
      <h2>{t("productDataset.create.step2.title")}</h2>
      <p>{t("productDataset.create.step2.intro")}</p>

      {columnsToMap.map((column, i) => {
        const mapping = columnMapping[column.id]
        return (
          <div key={i}>
            <Option key={i}>
              <Label>
                {column.valid ? <Valid /> : <Invalid />}
                {column.label}:
              </Label>
              <SingleSelect
                emptyText={t("productDataset.create.step2.selectColumn")}
                onSelect={(value) =>
                  setColumnMapping({ ...columnMapping, [column.id]: value })
                }
                options={
                  mapping
                    ? [
                        ...options,
                        {
                          value: mapping,
                          label: mapping,
                        },
                      ]
                    : options
                }
                value={mapping}
              />

              {!column.valid && (
                <HelpText hasError>{column.validationMessage}</HelpText>
              )}
            </Option>
            <HelpText>{column.explanation}</HelpText>
            {column.id === "id" ? (
              <p className="explanation-block">
                <strong>Tip: </strong>
                Make sure to use the same ID that you use in your customer data
                platform or analytics to make customer journey tracking easier.
                The ID can't be easily changed later!
              </p>
            ) : null}
          </div>
        )
      })}
      {priceIndex !== undefined && (
        <div>
          <Option>
            <Label>
              <Valid />
              {t("productDataset.create.step2.labels.currency")}
            </Label>

            <CurrencySelect value={priceCurrency} onSelect={setPriceCurrency} />
          </Option>
          <HelpText>
            {t("productDataset.create.step2.explanation.currency")}
          </HelpText>
        </div>
      )}
      <ButtonBar
        left={
          <Button onClick={() => previous && previous()}>{t("back")}</Button>
        }
        right={
          <Button
            primary
            disabled={next ? false : true}
            onClick={() => {
              next && next()
            }}
          >
            {t("next")}
          </Button>
        }
      />
      {children}
    </>
  )
}

export default MapColumns
