import {
  AdvisorValidationResult,
  Notice,
  NoticeType,
  ValidationCode,
} from "~/types"
import React from "react"
import { useTranslation } from "react-i18next"
import Warning from "~/components/icons/Warning"
import Info from "~/components/icons/Info"
import styled from "styled-components"
import { Link } from "react-router-dom"
import useNavigation, { NavigationMap } from "~/hooks/useNavigation"
import { Advisor } from "~/store/advisors"
// import Emoji from "~/components/Emoji"
import { Check } from "~/components/icons"
import useAdviceScreen from "~/hooks/useAdviceScreen"

const Icon = styled.div<{ isWarning?: boolean }>`
  color: ${(p) => (p.isWarning ? p.theme.secondary : p.theme.warning)};
  margin-right: 1rem;
`

const catalogueLinkBuilder =
  (advisorId: string, map: NavigationMap) =>
  (notice: Notice): React.ReactNode => {
    const { t } = useTranslation()

    const [link, transKey] =
      notice.code === ValidationCode.CLASSIFICATION_INCOMPLETE
        ? [map.advice(advisorId), "advisor.navigation.advice"]
        : notice.code === ValidationCode.CLASSIFICATION_EMPTY
        ? [map.advice(advisorId), "advisor.navigation.advice"]
        : notice.code === ValidationCode.NO_PRODUCTS_FOUND
        ? [map.catalogue(advisorId), "advisor.navigation.catalogue"]
        : notice.code === ValidationCode.MISSING_SECONDARY_SORT_COLUMN
        ? [map.adviceSettings(advisorId), "advisor.navigation.advice"]
        : [undefined, undefined]

    return link ? (
      <Link to={link} className="link">
        {t("integration.release.validations.fix")} {t(`${transKey}.title`)}
        {/* <Emoji size={16} emoji={t(`${transKey}.icon`)} /> */}
      </Link>
    ) : undefined
  }

const customerInfoValidationCodes = [
  "HEADER_MIN_LENGTH",
  "INTRO_MIN_LENGTH",
  "SUBMITTED_MESSAGE_MIN_LENGTH",
  "CTA_BUTTON_MIN_LENGTH",
  "CHECKBOX_MIN_LENGTH",
  "ID_MIN_LENGTH"
]

const NoticeList: React.FC<{
  result: AdvisorValidationResult
  advisor: { id: string; productLabel: string }
  theme?: "dark" | "light"
  linkCallback?: () => void
}> = (props) => {
  const { result, advisor, linkCallback } = props
  const theme = props.theme || "light"
  const { map } = useNavigation()
  const { t } = useTranslation()
  const { adviceScreen } = useAdviceScreen(advisor.id)
  const noValidations = Object.values(result).every((v) => v.length === 0)

  const anchor = (link: string) => (
    <Link to={link} className="link" onClick={linkCallback && linkCallback}>
      {t("integration.release.validations.fix")}{" "}
      {t("advisor.navigation.conversation.title")}
      {/* <Emoji size={16} emoji={t("advisor.navigation.conversation.icon")} /> */}
    </Link>
  )

  const linkBuilder = (notice: Notice): React.ReactNode => {
    const { questionId, ruleId, screenId } = notice.meta
    if (notice.code === ValidationCode.FLOW_CYCLE && questionId) {
      return anchor(map.rulesOfQuestion(advisor.id, questionId))
    }
    if (ruleId && questionId) {
      return anchor(map.ruleOfQuestion(advisor.id, questionId, ruleId))
    }
    if (screenId) {
      if (customerInfoValidationCodes.includes(notice.code)) {
        return anchor(
          map.conversationAdviceCustomerInfo(advisor.id, screenId)
        )
      }
      return anchor(map.screenInAdvisor(advisor.id, screenId))
    }
    if (questionId) {
      return anchor(map.questionInAdvisor(advisor.id, questionId))
    }
  }

  const adviceLinkBuilder = (notice: Notice): React.ReactNode => {
    if (customerInfoValidationCodes.includes(notice.code)) {
      return anchor(
        map.conversationAdviceCustomerInfo(advisor.id, adviceScreen.id)
      )
    } else if (notice.code === ValidationCode.ADVICE_SCREEN_UNREACHABLE) {
      return anchor(map.conversation(advisor.id))
    } else {
      return undefined
    }
  }

  const conversationValidations = [
    ...result.questions,
    ...result.media,
    ...result.answers,
    ...result.rules,
    ...result.catalogue,
    ...result.screens,
  ]

  if (noValidations) {
    return (
      <div className="data-list__li">
        <div>
          <Check /> {t("integration.release.empty")}{" "}
        </div>
      </div>
    )
  }

  return (
    <div className={"data-list theme-" + theme}>
      {conversationValidations.length > 0 && (
        <>
          {result.questions.length > 0 && (
            <List
              notices={result.questions}
              translationPrefix="advisor.notices.questions"
              linkBuilder={linkBuilder}
              productLabel={advisor.productLabel}
            />
          )}
          {result.answers.length > 0 && (
            <List
              notices={result.answers}
              translationPrefix="advisor.notices.answers"
              linkBuilder={linkBuilder}
              productLabel={advisor.productLabel}
            />
          )}
          {result.rules.length > 0 && (
            <List
              notices={result.rules}
              translationPrefix="advisor.notices.rules"
              linkBuilder={linkBuilder}
              productLabel={advisor.productLabel}
            />
          )}
          {result.screens.length > 0 && (
            <List
              notices={result.screens}
              translationPrefix="advisor.notices.screens"
              linkBuilder={linkBuilder}
              productLabel={advisor.productLabel}
            />
          )}
          {result.media.length > 0 && (
            <List
              notices={result.media}
              translationPrefix="advisor.notices.media"
              linkBuilder={(notice) =>
                anchor(
                  map.mediaOfQuestion(advisor.id, notice.meta.questionId!!)
                )
              }
              productLabel={advisor.productLabel}
            />
          )}
          {result.catalogue.length > 0 && (
            <List
              notices={result.catalogue}
              translationPrefix="advisor.notices.catalogue"
              linkBuilder={catalogueLinkBuilder(advisor.id, map)}
              productLabel={advisor.productLabel}
            />
          )}
        </>
      )}
      {result.advice.length > 0 && (
        <List
          notices={result.advice}
          translationPrefix="advisor.notices.advice"
          linkBuilder={adviceLinkBuilder}
          productLabel={advisor.productLabel}
        />
      )}

      {result.lookAndFeel.length > 0 && (
        <List
          notices={result.lookAndFeel}
          translationPrefix="advisor.notices.lookAndFeel"
          linkBuilder={(notice) => {
            return (
              <Link to={map.lookAndFeel(advisor.id)} className="link">
                {t("integration.release.adjust")}{" "}
                {t("advisor.navigation.lookAndFeel.title")}{" "}
                {/* <Emoji
                  size={16}
                  emoji={t("advisor.navigation.lookAndFeel.icon")}
                /> */}
              </Link>
            )
          }}
          productLabel={advisor.productLabel}
        />
      )}
    </div>
  )
}

const List: React.FC<{
  notices: Notice[]
  translationPrefix: string
  productLabel: string
  linkBuilder: (notice: Notice) => React.ReactNode
}> = ({ notices, translationPrefix, linkBuilder, productLabel }) => {
  const { t } = useTranslation()
  return (
    <>
      {notices.map((notice, i) => {
        const link = linkBuilder(notice)
        return (
          <div className="data-list__row" key={i}>
            <div className="data-list__row__status">
              <Icon isWarning={notice.type === NoticeType.WARNING}>
                {notice.type === NoticeType.WARNING ? <Info /> : <Warning />}
              </Icon>
            </div>
            <div className="data-list__row__field">
              <p>
                {t(`${translationPrefix}.${notice.code}`, { productLabel })}
              </p>
              <p>{link}</p>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default NoticeList
