import Field from "~/components/forms/Field"
import HelpText from "~/components/HelpText"
import MarkdownTextarea from "~/components/MarkdownTextarea"
import ToggleSwitch from "~/components/ToggleSwitch"
import React from "react"
import { useTranslation } from "react-i18next"
import { InfoPageCustomerInfoConfig, NoResultsCustomerInfoConfig } from "~/store/advisors"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import TutorialLink from "~/components/TutorialLink"

const ScreenCustomerInfoEditor: React.FC<{
  customerInfo: InfoPageCustomerInfoConfig | NoResultsCustomerInfoConfig
  updateCustomerInfo: (
    customerInfo: InfoPageCustomerInfoConfig | NoResultsCustomerInfoConfig
  ) => void
  extraFormFields?
}> = ({ customerInfo, updateCustomerInfo, extraFormFields }) => {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()

  return (
    <>
      {!featureFlags.customerInfoCollectionEnabled ? (
        <div className="hints mb-2">
          <p>
            This feature is available on the Professional license or higher. You can read more about it in the tutorial.
          </p>
          <TutorialLink
            article="email-results"
            label="Send the advice by e-mail"
          />
        </div>
      ) : null}
      <ToggleSwitch
        toggledOn={
          customerInfo.showForm && featureFlags.customerInfoCollectionEnabled
        }
        label={t("screenEditor.customerInfo.showForm")}
        onChange={(show) =>
          updateCustomerInfo({
            ...customerInfo,
            showForm: show,
          })
        }
      />
      <HelpText>
        {t("screenEditor.customerInfo.infoScreen.showFormHelp")}
      </HelpText>

      {customerInfo.showForm && (
        <div className="hints">
          <p>
            {t("screenEditor.customerInfo.hintStart")}
          </p>
          <TutorialLink
            article="email-results"
            label="Send the advice by e-mail"
          />
        </div>
      )}

      {extraFormFields && extraFormFields}

      <Field
        label={t("screenEditor.customerInfo.showCheckbox")}
        value={customerInfo.checkboxLabel}
        maxLength={64}
        markdownOpts={["link"]}
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            checkboxLabel: value,
          })
        }
        disabled={!customerInfo.showForm || !customerInfo.showCheckbox}
        labelExtra={
          <ToggleSwitch
            toggledOn={customerInfo.showForm && customerInfo.showCheckbox}
            label={t("screenEditor.customerInfo.showCheckbox")}
            flipLabel
            onChange={(show) =>
              updateCustomerInfo({
                ...customerInfo,
                showCheckbox: show,
              })
            }
          />
        }
      />
      <HelpText>{t("screenEditor.customerInfo.checkboxHelp")}</HelpText>
      <MarkdownTextarea
        disabled={!customerInfo.showForm}
        label={t("screenEditor.customerInfo.formSubmittedMessage")}
        text={customerInfo.formSubmittedMessage}
        maxLength={700}
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            formSubmittedMessage: value,
          })
        }
        bounds={"#sidebar"}
        dataTestId={"advice-customer-info-received-note"}
      />
      <HelpText>
        {t("screenEditor.customerInfo.formSubmittedMessageHelp")}
      </HelpText>
    </>
  )
}

export default ScreenCustomerInfoEditor
