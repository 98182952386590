import React from "react"
import styled from "styled-components"
import { InputState, Placeholders } from "~/styles/TextStyles"

const Input = styled.input<
  {
    frameless?: boolean
    fullWidth?: boolean
    hasError?: boolean
  } & React.InputHTMLAttributes<HTMLInputElement>
>`
  width: ${(props) => (props.frameless || props.fullWidth ? "100% !important" : "auto")};
  border-radius: ${(p) => p.theme.borderRadius};
  ${InputState}
  ${Placeholders}
`

export default Input
