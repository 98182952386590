import { trackChange } from "~/actions/advisors"
import { useAuth } from "~/context/auth"
import useHandleElmError from "~/hooks/useHandleElmError"
import React, { useEffect } from "react"
import Elm from "react-elm-components"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { EnvVar, envVar } from "~/utils/environment"

const ElmWrapper: React.FC<{
  ports: (ports: any | undefined) => void
  flags?: any
  src: any /** The elm module */
}> = ({ ports, flags, src: elmModule }) => {
  const [ports_, setPorts] = React.useState<any>()
  useHandleElmError(ports_)
  const dispatch = useDispatch()
  const history = useHistory()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const { token } = useAuth()

  const { organisationId } = useAuth()

  useEffect(() => {
    if (ports_) {
      const handler = ({ advisorId }) => {
        dispatch(trackChange(advisorId))
      }
      const gotoUrlHandler = function(url) {
        history.push(url)
        ports_?.urlChanged.send(window.location.href)
      }

      const urlChangeHandler = function() {
        ports_.urlChanged.send(window.location.href)
      }
      const selectInputTextHandler = function(inputId) {
        console.log("inputId", inputId, document.getElementById(inputId))
        // Wait for Elm DOM to update first
        window.requestAnimationFrame(() => {
          const elem = document.getElementById(inputId)
          if (elem && elem.tagName.toLowerCase() === "input") {
            ;(elem as HTMLInputElement).select()
          }
        })
      }
      const scrollIntoViewHandler = function(htmlId) {
        window.requestAnimationFrame(() => {
          document.getElementById(htmlId)?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          })
        })
      }

      document.addEventListener(
        "clipboard-copy",
        highlightCopiedToClipboardButton
      )

      window.addEventListener("popstate", urlChangeHandler)

      ports_?.trackChange?.subscribe(handler)
      ports_.scrollIntoView?.subscribe(scrollIntoViewHandler)
      ports_.selectText?.subscribe(selectInputTextHandler)
      ports_?.gotoUrl?.subscribe(gotoUrlHandler)

      return () => {
        ports_?.trackChange?.unsubscribe(handler)
        ports_.selectText?.unsubscribe(selectInputTextHandler)

        ports_?.gotoUrl.unsubscribe(gotoUrlHandler)
        ports_.scrollIntoView?.unsubscribe(scrollIntoViewHandler)
        window.removeEventListener("popstate", urlChangeHandler)

        document.removeEventListener(
          "clipboard-copy",
          highlightCopiedToClipboardButton
        )
      }
    }
  }, [dispatch, history, ports_])

  useEffect(() => {
    return () => {
      if (ports_) ports_.stop.send(true)
    }
  }, [ports_])

  const seeds = React.useMemo(
    () => Array.from(crypto.getRandomValues(new Uint32Array(4))),
    []
  )

  function setup(elmPorts) {
    setPorts(elmPorts)
    ports(elmPorts)
  }
  const locale =
    (navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language) || "en-US"

  const now = Date.now()

  return (
    <Elm
      src={elmModule}
      ports={setup}
      flags={{
        ...flags,
        seeds,
        organisationId: organisationId.orgId,
        now: now,
        locale: locale,
        url: window.location.href,
        apiUrl: apiUrl,
        authToken: token,
      }}
    />
  )
}

/**
 * UI feedback for the clipboard-copy custom element from  @github/clipboard-copy-element
 * */
function highlightCopiedToClipboardButton(event) {
  const button = event.target
  // @ts-ignore
  button?.classList.add("feedback")
  setTimeout(() => {
    button?.classList.remove("feedback")
  }, 2000)
}

export default ElmWrapper
