import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import React, { useState } from "react"
import Field from "~/components/forms/Field"
import Button from "~/components/Button"
import Logo from "~/components/Logo"
import HelpText from "~/components/HelpText"
import LoadingSpinner from "~/components/Loading"

const Forgot = styled(Link)`
  margin-top: 1rem;
  display: block;
  text-align: center;
  font-size: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
`

const DEFAULT_STATE = {
  errorMessage: undefined,
  authenticated: false,
}

const INCORRECT_CREDENTIALS_STATE = (errorMessage: string) => ({ errorMessage })

const LOGGED_IN = {
  errorMessage: undefined,
  authenticated: true,
}

type states = typeof LOGGED_IN | ReturnType<typeof INCORRECT_CREDENTIALS_STATE>
const LoginForm: React.FC<{
  login: (username: string, password: string) => Promise<any>
  email: string
  setEmail: (email: string) => void
}> = ({ login, email, setEmail }) => {
  const { t } = useTranslation()

  const [state, setState] = useState<states>(DEFAULT_STATE)
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const transition = (state: states) => setState({ ...DEFAULT_STATE, ...state })

  const onSubmit = async e => {
    e.preventDefault()

    setLoading(true)
    login(email, password)
    .catch(error => {
      setLoading(false)
      if (error.status >= 400 && error.status <= 499) {
        transition(INCORRECT_CREDENTIALS_STATE(t("login.incorrect")))
      } else {
        transition(INCORRECT_CREDENTIALS_STATE(t("login.error")))
      }
    })
  }

  return (
    <form onSubmit={onSubmit} data-test-id="login-form">
      <div style={{ textAlign: "center", marginBottom: "2rem" }}>
        <Logo style={{ width: "8rem" }} />
        <p style={{ marginTop: 0 }}>{t("login.intro")}</p>
      </div>
      <Field
        type="email"
        value={email}
        onChange={setEmail}
        label={t("login.email")}
        placeholder={t("login.emailPlaceholder")}
      />
      <div style={{ marginBottom: "2rem" }}>
        <Field
          value={password}
          onChange={setPassword}
          type="password"
          label={t("login.password")}
          placeholder={t("login.passwordPlaceholder")}
        />
      </div>
      {state.errorMessage && <HelpText hasError>{state.errorMessage}</HelpText>}
      <Button fullWidth primary>
        {loading ? <LoadingSpinner size="small" /> : t("login.loginBtn")}
      </Button>
      <Forgot data-test-id="forgot-password-link" to="/login/forgot-password">
        {t("login.forgotBtn")}
      </Forgot>
    </form>
  )
}

export default LoginForm
