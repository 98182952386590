import { dataSources } from "~/api"
import { DataSourceUpdate } from "~/api/dataSources"
import Card from "~/components/Card"
import LoadingSpinner from "~/components/Loading"
import Pagination from "~/components/Pagination"
import { useAuth } from "~/context/auth"
import { linkWithParam } from "~/lib/URLHelpers"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router"
import { DataSource } from "~/types"
import UpdateRecord from "./UpdateRecord"

const UpdateHistory: React.FC<{
  advisorId: string
  dataSource: DataSource
  productLabel: string
  dataSourceLabel: string
  updateOffset?: number
}> = (props) => {
  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(useLocation().search)
  const page = params.get("page")
  const currentPage = page ? parseInt(page) || 1 : 1
  const { updateOffset = 0 } = props
  const perPage = 10
  const [state, setState] = React.useState({
    updates: [] as DataSourceUpdate[],
    loading: false,
    total: 0,
  })

  React.useEffect(() => {
    setState((state) => ({ ...state, loading: true }))
    dataSources
      .updates(
        props.advisorId,
        props.dataSource.id,
        {
          offset: (currentPage - 1) * perPage + updateOffset,
          size: perPage,
        },
        token!!,
        organisationId
      )
      .then((paginatedUpdates) => {
        setState((state) => ({
          ...state,
          updates: paginatedUpdates.results,
          loading: false,
          total: paginatedUpdates.total,
        }))
      })
  }, [props.advisorId, props.dataSource.id, currentPage, token, updateOffset, organisationId])

  const gotoPage = (page: number) => {
    history.push(
      linkWithParam(location.pathname, location.search, "page", `${page}`)
    )
  }

  const pagination = (
    <Pagination
      currentPage={currentPage}
      currentPageLength={state.updates.length}
      gotoPage={gotoPage}
      perPage={perPage}
      totalItems={state.total}
    />
  )
  return (
    <Card style={{ marginTop: "2rem" }}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        {t("productDataset.feed.history.title")}{" "}
        {state.loading && <LoadingSpinner size="small" />}
      </h2>

      {pagination}

      {state.updates ?
      state.updates.map((update, i) => {
        return (
          <UpdateRecord
            key={i}
            update={update}
            productLabel={props.productLabel}
            dataSourceLabel={props.dataSourceLabel}
          />
        )
      }) : <p>{t("productDataset.feed.history.empty")}</p>}

      {pagination}
    </Card>
  )
}

export default UpdateHistory
