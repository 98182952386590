import React, { useState } from "react"
import { Switch, Route, useRouteMatch, Redirect } from "react-router"
import UpdateExistingData from "./UpdateExistingData"
import { DataSource } from "~/types"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import useDatasources from "~/hooks/useDatasources"
import useMountEffect from "~/hooks/useMountEffect"
import LoadingSpinner from "~/components/Loading"
import SplitLayout, { SidebarContent } from "~/components/SplitLayout"
import HelpText from "~/components/HelpText"
import { useTranslation } from "react-i18next"
import UpdateHistory from "./GoogleShoppingFeed/UpdateHistory"
import { ButtonLink } from "~/components/Button"
import ArrowLeft from "~/components/icons/ArrowLeft"
import Page, { PageHeader } from "~/components/advisors/Page"
import useNavigation from "~/hooks/useNavigation"
import { Catalogue } from "~/elm-tsx-wrappers/Catalogue"

export default () => {
  const { url } = useRouteMatch()
  const { t } = useTranslation()

  const [dataSource, setDataSource] = useState<DataSource | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { id: advisorId, productLabel} = useAdvisorContext()
  const { getAll } = useDatasources(advisorId)
  const { toCatalogueCreate } = useNavigation()

  const dataSourceLabel =
    dataSource?.type === "GOOGLE_SHOPPING_FEED"
      ? t("productDataset.feed.item.feedback.dataSource.googleShoppingFeed")
      : dataSource?.type === "CSV"
      ? t("productDataset.feed.item.feedback.dataSource.csv")
      : ""

  useMountEffect(() => {
    let isCancelled = false

    getAll(advisorId)
      .then((sources) => {
        if (!isCancelled) {
          const ds = sources[0]
          if (ds) {
            setDataSource(sources[0])
          } else {
            toCatalogueCreate(advisorId)
          }
        }
      })
      .catch((e) => {
        setError(true)
      })
      .finally(() => setLoading(false))

    return () => {
      isCancelled = true
    }
  })

  if (error)
    return (
      <SplitLayout
        left={
          <SidebarContent padTop>
            <HelpText hasError>{t("errors.error")}</HelpText>
          </SidebarContent>
        }
      />
    )

  if (loading)
    return (
      <Page>
        <LoadingSpinner />
      </Page>
    )

  if (!dataSource) return null

  return (
    <Switch>
      {dataSource.type === "GOOGLE_SHOPPING_FEED" ? (
        <Route
          exact
          path={`${url}/update-history`}
          render={(props) => (
            <Page maxWidth>
              <PageHeader>
                <h1>{t("productDataset.title", { productLabel })}</h1>
              </PageHeader>
              <div>
                <ButtonLink to={url}>
                  <ArrowLeft /> {t("back")}
                </ButtonLink>
              </div>
              <UpdateHistory
                dataSource={dataSource}
                productLabel={productLabel}
                dataSourceLabel={dataSourceLabel}
                advisorId={advisorId}
              />
            </Page>
          )}
        />
      ) : (
        <Route
          path={`${url}/update`}
          render={(props) => (
            <Page>
              <PageHeader>
                <h1>{t("productDataset.title", { productLabel })}</h1>
              </PageHeader>
              <UpdateExistingData
                {...props}
                dataSource={dataSource}
                onUpload={(datasource) => setDataSource(datasource)}
              />
            </Page>
          )}
        />
      )}

      <Route
        exact={false}
        path={`${url}`}
        render={(props) => (
          <Catalogue advisorId={advisorId} dataSource={dataSource} />
        )}
      />

      <Redirect to={url} />
    </Switch>
  )
}
