import React from "react"
import styled, {css} from "styled-components"
import ChevronForward from "./icons/ChevronForward"
import ChevronBack from "./icons/ChevronBack"
import Dropdown from "./Dropdown"
import { useTranslation } from "react-i18next"
import ChevronLastPage from "./icons/ChevronLastPage"
import ChevronFirstPage from "./icons/ChevronFirstPage"

const PageNavBtns = styled.div`
  display: flex;
  color: ${(p) => p.theme.main};
  & > * {
    margin: 0 0.25rem;
  }
`
const PageNav = styled.div<{ disabled?: boolean }>`
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};
  background: ${(p) => (p.disabled ? p.theme.shade4 : p.theme.shade3)};

  pointer-events: ${(p) => (p.disabled ? "none" : "all")};
  padding: 0.3rem;
  border-radius: ${(p) => p.theme.borderRadius};
  display: flex;
  ${(p) =>
    p.disabled &&
    css`
      background-color: ${p.theme.shade3};
      color: ${p.theme.shade4};
      border-color: ${p.theme.shade2};
    `}
`

const PaginationContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  font-size: 0.875rem;
  color: ${(p) => p.theme.shade4};
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  align-items: center;
  & > * {
    margin: 0 1rem;
  }
  &,
  ${PageNavBtns} {
    & > * {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Pagination: React.FC<{
  perPage: number
  perPageOptions?: number[]
  currentPage: number
  currentPageLength: number
  totalItems: number
  gotoPage: (page: number) => void
}> = ({
  perPage = 25,
  perPageOptions = [25],
  totalItems = 134,
  currentPage = 1,
  currentPageLength = 25, //0,
  gotoPage,
}) => {
  const { t } = useTranslation()
  const lastPage = Math.ceil(totalItems / perPage) || 1

  const currentLabel = t("components.pagination.perPage", { count: perPage })

  const offset = (currentPage - 1) * perPage
  const range = t("components.pagination.range", {
    start: totalItems ? offset + 1 : 0,
    end: offset + currentPageLength,
    max: totalItems,
  })

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === lastPage

  return (
    <PaginationContainer data-test-id="pagination">
      {perPageOptions.length > 1 && (
        <Dropdown
          options={perPageOptions.map((opt) => ({
            label: `${opt}`,
            value: opt,
          }))}
          value={perPage}
          currentLabel={currentLabel}
          onSelect={() => {}}
        />
      )}

      <div>{range}</div>
      <PageNavBtns>
        <PageNav disabled={isFirstPage} onClick={() => gotoPage(1)}>
          <ChevronFirstPage />
        </PageNav>
        <PageNav
          disabled={isFirstPage}
          onClick={() => gotoPage(currentPage - 1)}
        >
          <ChevronBack />
        </PageNav>
        <PageNav
          disabled={isLastPage}
          onClick={() => gotoPage(currentPage + 1)}
        >
          <ChevronForward />
        </PageNav>
        <PageNav disabled={isLastPage} onClick={() => gotoPage(lastPage)}>
          <ChevronLastPage />
        </PageNav>
      </PageNavBtns>
    </PaginationContainer>
  )
}

export default Pagination
