import { Invite } from "~/api/accounts"
import Avatar from "~/components/Avatar"
import Button from "~/components/Button"
import Input from "~/components/forms/Input"
import Delete from "~/components/icons/Delete"
import LoadingSpinner from "~/components/LoadingSpinner"
import Modal, { ModalContent, ModalButtons } from "~/components/Modal"
import { SingleSelect } from "~/components/selection"
import useCurrentUser from "~/hooks/useCurrentUser"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import reactStringReplace from "react-string-replace"
import { Account, Role }from "~/store/currentUser/types"
import styled, { css } from "styled-components"
import { User } from "."

type UserTableProps = {
  users: User[]
  invited: Invite[]
  updating: string[]
  updateUser: (user: User) => void
  deleteUser: (user: User) => void
  updateInvite: (user: User) => void
  deleteInvite: (user: User) => void
  resendInvite: (user: User) => void
}

const Table = styled.table`
  width: 100%;
  margin: 2rem auto;
  border-collapse: collapse;

  th {
    text-align: left;
  }
  tr {
    border-bottom: 2px solid ${(p) => p.theme.shade2};
  }
`

const UserRow = styled.tr<{ updating?: boolean }>`
  td,th {
    border: none;
  }
  .resend-link {
    color: ${(p) => p.theme.secondary};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  td {
    padding: 0.5rem 0.5rem;
    vertical-align: middle;
  }
  td:nth-child(1) {
    width: 3rem;
  }
  td:nth-child(2) {
    font-weight: 400;
    .highlight {
      color: ${(p) => p.theme.secondary};
    }
    div {
      font-weight: 400;
      font-size: 0.75rem;
      color: ${(p) => p.theme.shade4};
    }
  }
  td:nth-child(3) {
    width: 10rem;
  }
  td:nth-child(4),
  td:nth-child(5) {
    width: 2rem;
    padding: 0.5rem 0.25rem;
    text-align: center;
  }
  ${(p) =>
    p.updating &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const highlight = (str: string, filter: string) =>
  filter
    ? reactStringReplace(str, filter, (match, i) => (
        <span key={i} className="highlight">
          {match}
        </span>
      ))
    : str

const UserRowComp = ({ updating, user, updateUser, deleteUser, filter }) => {
  const { t } = useTranslation()
  const [confirm, setConfirm] = useState(false)

  const { currentUser } = useCurrentUser()
  const isCurrentUser = currentUser?.id === user.id

  const highlightedName = highlight(user.familyName || user.firstName, filter)
  const highlightedEmail = highlight(user.email, filter)

  return (
    <UserRow updating={updating}>
      <td>
        <Avatar
          name={user.firstName || user.email}
          image={user.profileImage?.urls["64x64.jpg"]}
        />
      </td>
      <td>
        {highlightedName}
        <div>{highlightedEmail}</div>
      </td>
      <td>
        <SingleSelect
          options={
            isCurrentUser
              ? [{ label: t("roles.admin"), value: Role.Admin }]
              : [
                  {
                    label: t("roles.admin"),
                    value: Role.Admin,
                  },
                  {
                    label: t("roles.contributor"),
                    value: Role.Contributor,
                  },
                ]
          }
          value={user.role}
          onSelect={(value) => {
            updateUser({
              ...user,
              role: value,
            })
          }}
        />
      </td>
      <td>
        {!isCurrentUser && (
          <div>
            <Button iconOnly onClick={() => setConfirm(true)}>
              <Delete />
            </Button>
          </div>
        )}
        {confirm && (
          <Modal onClose={() => setConfirm(false)}>
            <ModalContent style={{ maxWidth: "40rem" }}>
              <h2>{t("settings.users.overview.deleteConfirmHeader")}</h2>
              <p>{t("settings.users.overview.deleteConfirmText")}</p>
              <ModalButtons>
                <Button
                  hasIconLeft
                  onClick={() => {
                    setConfirm(false)
                    deleteUser(user)
                  }}
                  data-test-id="delete-account-modal"
                >
                  <Delete />
                  {t("settings.users.overview.deleteButton")}
                </Button>
                <Button primary onClick={() => setConfirm(false)}>
                  {t("cancel")}
                </Button>
              </ModalButtons>
            </ModalContent>
          </Modal>
        )}
      </td>
      <td>{updating && <LoadingSpinner size="small" />}</td>
    </UserRow>
  )
}

const InviteRowComp = ({
  user,
  filter,
  updating,
  updateInvite,
  deleteInvite,
  resendInvite,
}) => {
  const { t } = useTranslation()
  const highlightedName = highlight(user.familyName || user.firstName, filter)

  return (
    <UserRow>
      <td>
        <Avatar name={user.firstName} />
      </td>
      <td>
        {highlightedName}
        <div>
          {user.expired
            ? t("settings.users.overview.invitationExpired")
            : t("settings.users.overview.invitePending")}{" "}
          &ndash;{" "}
          <span
            className="resend-link"
            onClick={() => {
              resendInvite(user)
            }}
          >
            {t("settings.users.overview.resendInvitation")}
          </span>
        </div>
      </td>
      <td>
        <SingleSelect
          options={[
            {
              label: t("roles.admin"),
              value: Role.Admin,
            },
            {
              label: t("roles.contributor"),
              value: Role.Contributor,
            },
          ]}
          value={user.role}
          onSelect={(value) => updateInvite({ ...user, role: value })}
        />
      </td>
      <td>
        <Button iconOnly onClick={() => deleteInvite(user)}>
          <Delete />
        </Button>
      </td>
      <td>{updating && <LoadingSpinner size="small" />}</td>
    </UserRow>
  )
}

const inviteToAccount = (invite: Invite): Account & { expired: boolean } => ({
  id: invite.id,
  email: invite.email,
  firstName: invite.email,
  familyName: invite.email,
  profileImage: undefined,
  updatedAt: 0,
  role: invite.role,
  expired: invite.expired,
  emailPreferences: invite.emailPreferences,
})

const UserTable: React.FC<UserTableProps> = (props) => {
  const [filter, setFilter] = useState("")
  const { t } = useTranslation()

  const users = props.users.map((u) => ({ ...u, type: "account" }))
  const invited = props.invited.map((i) => ({
    ...inviteToAccount(i),
    type: "invite",
  }))

  const sortedUsers = [...users, ...invited]
    .sort((a, b) => {
      if (a.familyName < b.familyName) {
        return -1
      }
      if (a.familyName > b.familyName) {
        return 1
      }
      return 0
    })
    .filter((user) => {
      if (filter) {
        const lcFilter = filter.toLowerCase()
        return (
          user.familyName.toLowerCase().includes(lcFilter) ||
          user.firstName.toLowerCase().includes(lcFilter) ||
          user.email.toLowerCase().includes(lcFilter)
        )
      } else {
        return true
      }
    })

  return (
    <>
      <Input
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
        placeholder={t("settings.users.overview.filterPlaceholder")}
        style={{ width: "15rem" }}
      />
      <Table>
        <thead>
          <tr>
            <th>{t('settings.users.overview.headers.user')}</th>
            <th></th>
            <th>{t('settings.users.overview.headers.role')}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user, index) => {
            return user.type === "account" ? (
              <UserRowComp
                key={user.id + user.email}
                updating={props.updating.includes(user.id)}
                user={user}
                updateUser={props.updateUser}
                deleteUser={props.deleteUser}
                filter={filter}
              />
            ) : (
              <InviteRowComp
                key={user.id + user.email}
                user={user}
                filter={filter}
                updating={props.updating.includes(user.id)}
                updateInvite={props.updateInvite}
                deleteInvite={props.deleteInvite}
                resendInvite={props.resendInvite}
              />
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default UserTable
