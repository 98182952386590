import { EnvVar, envVar } from "~/utils/environment"

type ArticleMap = {
  [key: string]: string
}

const articleNames = [
  "email-results",
  "empty-advice",
  "embed-guided-selling-app",
  "embed-add-aiden-script",
  "embed-popup",
  "embed-in-page",
  "embed-full-page",
  "drill-down",
  "catalogue",
  "catalogue-feed",
  "catalogue-csv",
  "catalogue-variants",
  "matching-rules",
  "filter-question",
  "squeezely",
  "google-analytics",
  "google-tag-manager",
  "custom-integration",
  "impact-analytics",
] as const

export type ArticleName = (typeof articleNames)[number]

function isArticleName(name: any): name is ArticleName {
  return articleNames.includes(name)
}

const prodArticles: ArticleMap = {
  "email-results": "82958",
  "empty-advice": "82946",
  "embed-guided-selling-app": "82948",
  "embed-add-aiden-script": "82948",
  "embed-popup": "82949",
  "embed-in-page": "82950",
  "embed-full-page": "82951",
  "drill-down": "82722",
  catalogue: "79815",
  "catalogue-feed": "82751",
  "catalogue-feed-filter": "82751",
  "catalogue-csv": "82935",
  "catalogue-variants": "82938",
  "matching-rules": "82940",
  "filter-question": "82944",
  squeezely: "82961",
  "google-analytics": "82956",
  "google-tag-manager": "96960",
  "custom-integration": "82957",
  "impact-analytics": "82955",
}

const prodArticleChapters: ArticleMap = {
  "catalogue-feed-filter": "h_8dbffe10f0",
  "embed-add-aiden-script": "h_ea717affcc",
}

const devArticles: ArticleMap = {
  catalogue: "78510",
}

function getIntercomArticle(name: string): string {
  if (!isArticleName(name)) throw new Error(`Article ${name} doesn't exist.`)

  const intercomEnv = envVar(EnvVar.INTERCOM_APP_ID)?.toString()
  switch (intercomEnv) {
    case "cz3yd3j0":
      return typeof prodArticles[name] !== "undefined"
        ? prodArticles[name]
        : "0"
    default:
      return typeof devArticles[name] !== "undefined"
        ? devArticles[name]
        : "78363"
  }
}

export function getIntercomArticleURL(name: string): string | undefined {
  if (!isArticleName(name)) throw new Error(`Article ${name} doesn't exist.`)

  const id = prodArticles[name]
  if (!id) return undefined
  const chapter = prodArticleChapters[name]
  return new URL(
    chapter ? id + "#" + chapter : id,
    "https://support.aiden.cx/en/articles/"
  ).toString()
}

export function getArticleOnClick(articleName: string): () => void {
  return () => {
    try {
      window.open(getIntercomArticleURL(articleName), "_blank")
      // @ts-ignore
      //   if (window.Intercom) {
      //   // @ts-ignore
      //   window.Intercom("showArticle", getIntercomArticle(articleName))
      // } else {
      //   window.open(getIntercomArticleURL(articleName), "_blank")
      // }
    } catch (e) {
      console.error(e)
    }
  }
}
