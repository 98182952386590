import axios, { AxiosError } from "axios"
import { OrganisationId } from "./types"

export type Config = {
  path: string
  data?: Record<string, any>
  params?: Record<string, any>
  headers?: Record<string, any>
  organisationId: OrganisationId | null
  token?: string
  onUploadProgress?: (progressEvent) => void
}

export type ApiError = {
  status: number
  data: Record<string, any>
  message: string
}

const req = async <T>(
  domain: string,
  method: "get" | "post" | "put" | "patch" | "delete",
  config: Partial<Config>
): Promise<T> => {
  try {
    const resp = await axios({
      method: method,
      headers: withOrgHeader(config.organisationId?.orgId, {
        "Content-type": "application/json",
        Authorization: `Bearer ${config.token || ""}`,
        ...(config.headers || {}),
      }),
      url: `${domain}${config.path}`,
      data: config.data,
      params: config.params,
      onUploadProgress: config.onUploadProgress,
    })

    if (resp.status >= 200 && resp.status < 300) {
      return Promise.resolve(resp.data)
    } else {
      const e: ApiError = {
        status: resp.status,
        data: resp.data || {},
        message: resp.data.message || "",
      }
      return Promise.reject(e)
    }
  } catch (e) {
    console.error("API ERROR", e)
    const error = e as AxiosError
    return Promise.reject({
      status: error?.response?.status || 500,
      data: error?.response?.data || { errorCode: "error" },
      message: error?.response?.data.message || "",
    })
  }
}

/**
 * API Wrapper around Axios to absract Axios away.
 * Use this instead of Axios directly.
 */
const APIBase = (domain: string) => ({
  get: <T>(config: Omit<Config, "data">) => req<T>(domain, "get", config),
  post: <T>(config: Config) => req<T>(domain, "post", config),
  put: <T>(config: Config) => req<T>(domain, "put", config),
  patch: <T>(config: Config) => req<T>(domain, "patch", config),
  delete: <T>(config: Config) => req<T>(domain, "delete", config),
})

export default APIBase
function withOrgHeader(
  organisationId: string | null | undefined,
  headers: Record<string, any>
): Record<string, any> {
  if(organisationId){
    return {...headers, "X-AIDEN-SCOPE": organisationId}
  }
  return headers
}
