import React, { useState, useEffect } from "react"
import styled from "styled-components"
import CardItem from "~/components/CardItem"
import { useLocation, useHistory } from "react-router-dom"
import qs from "qs"
import InvitationForm from "./InvitationForm"
import Logo from "~/components/Logo"
import LoadingSpinner from "~/components/LoadingSpinner"
import { accounts } from "~/api"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import { useAuth } from "~/context/auth"

const Wrapper = styled.div`
  display: block;
  min-height: 100vh;
  padding-top: 10vh;
  ${CardItem} {
    max-width: 24rem;
    margin: 0 auto;
    width: 100%;
  }
`

export default () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const token: string = qs.parse(
    location.search.substring(1, location.search.length)
  )["token"] as string
  const { logout } = useAuth()

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    accounts
      .acceptInvitation(token)
      .then(response => {
        setEmail(response.email)
        // log the user out before continueing
        // should be changed once we have multi account support
        logout()
      })
      .catch(e => {
        if (e.status === 500) {
          setError(t("errors.error"))
        } else {
          setError(t("invitation.error.not_found"))
        }
      })
      .finally(() => setLoading(false))
  }, [token, t, logout])

  useEffect(() => {
    if (!token) history.replace("/login")
  }, [token, history])

  return (
    <Wrapper>
      <CardItem>
        <div style={{ textAlign: "center" }}>
          <Logo style={{ width: "8rem" }} />
        </div>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <LoadingSpinner />
          </div>
        ) : error ? (
          <HelpText hasError>{error}</HelpText>
        ) : (
          <InvitationForm inviteToken={token} email={email} />
        )}
      </CardItem>
    </Wrapper>
  )
}
