import { TextareaAutogrow } from "~/components/forms/Textarea"
import Label from "~/components/forms/Label"
import MarkdownTextarea from "~/components/MarkdownTextarea"
import TabNav from "~/components/TabNav"
import { useTranslation } from "react-i18next"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { Advisor }from "~/store/advisors"
import { InfoScreen }from "~/store/screens"
import styled from "styled-components"
import InfoScreenRuleEditor from "./InfoScreenRuleEditor"
import QuestionLabelEditor from "~/components/QuestionLabelEditor"
import QuestionColorEditor from "~/components/QuestionColorEditor"
import InfoScreenMediaEditor from "../MediaEditor/InfoScreenMediaEditor"
import HelpText from "~/components/HelpText"
import useInfoScreen from "~/hooks/useInfoScreen"
import InfoScreenCustomerInfoEditor from "./InfoScreenCustomerInfoEditor"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"

const Section = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const InfoScreenEditor: React.FC<{
  advisor: Advisor
  screen: InfoScreen
}> = ({ advisor, screen }) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const { featureFlags } = useFeatureFlags()
  const infoScreen = useInfoScreen(advisor.id, screen.id)

  return (
    <>
      <Section>
        <TabNav
          withUnderLineBorder={true}
          exact={true}
          options={
            [
              {
                label: (
                  <>
                    <span>Content</span>
                  </>
                ),
                link: `${url}`,
              },
              {
                label: (
                  <>
                    <span>Media</span>
                  </>
                ),
                link: `${url}/media`,
              },
              {
                label: (
                  <>
                    <span>Flow</span>
                  </>
                ),
                link: `${url}/flow`,
              },
              {
                label: (
                  <>
                    <span>Customer contact</span>
                  </>
                ),
                link: `${url}/customer-contact`,
              },
            ].filter((elem) => !!elem) as any
          }
        />
      </Section>

      <Switch>
        <Route
          path={`${url}/customer-contact`}
          exact
          render={({ match }) => <InfoScreenCustomerInfoEditor advisor={advisor} screenId={screen.id} />}
        />
        <Route
          path={`${url}/flow`}
          exact
          render={({ match }) => (
            <InfoScreenRuleEditor advisorId={advisor.id} screenId={screen.id} />
          )}
        />
        <Route
          path={`${url}/media`}
          exact
          render={({ match }) => (
            <InfoScreenMediaEditor advisorId={advisor.id} screenId={screen.id} />
          )}
        />
        <Route
          path={`${url}`}
          exact
          render={({ match }) => (
            <>
              <p>
                Use an info page to give feedback for a specific answer. Or to
                provide some context for a new set of questions coming up.
              </p>

              <div>
                <Label>{t("screenEditor.fields.title")}</Label>
              </div>
              <TextareaAutogrow
                value={screen.title}
                maxLength={255}
                onChange={(e: any) => infoScreen.changeScreenTitle(e.target.value)}
              />
              <div>
                <Label>{t("questionsEditor.questionLabel")}</Label>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "max-content max-content",
                  gap: "0.5rem",
                }}
              >
                <QuestionLabelEditor
                  onChange={(e: any) =>
                    infoScreen.changeScreenLabel(e.target.value.slice(0, 24))
                  }
                  value={screen.label}
                  color={screen.color}
                />
                <QuestionColorEditor
                  onSelect={(value: any) => infoScreen.changeScreenColor(value)}
                  value={screen.color}
                />
              </div>
              {screen.label.length < 3 ? (
                <HelpText hasError>
                  {t("questionsEditor.validations.label")}
                </HelpText>
              ) : (
                <HelpText>{t("questionsEditor.screenLabelHelp")}</HelpText>
              )}

              <MarkdownTextarea
                label={t("screenEditor.fields.text")}
                id={screen.id + "-text"}
                isOptional={true}
                text={screen.text}
                maxLength={700}
                onChange={(value) => infoScreen.changeScreenText(value)}
                bounds={"#sidebar"}
                dataTestId={"advice-text"}
                toolbar={["bold", "italic", "link", "list"]}
              />

              <MarkdownTextarea
                label={t("screenEditor.fields.additionalInformation")}
                id={screen.id + "-info"}
                text={screen.info}
                isOptional={true}
                maxLength={700}
                onChange={(value) => infoScreen.changeScreenInfo(value)}
                bounds={"#sidebar"}
                dataTestId={"advice-additional"}
                toolbar={["bold", "link"]}
                italic
              />
            </>
          )}
        />
        <Redirect to={`${url}`} />
      </Switch>
    </>
  )
}
export default InfoScreenEditor
