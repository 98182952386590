import API from "./api"
import { UUID }from "~/store/types"
import {
  FilterAPIObj,
  Column,
  ColumnType,
} from "~/components/advisors/Products/Advice/ProductMapping/Filters/types"
import { OrganisationId, Paginated } from "./types"

export type MappingValue = "bad" | "neutral" | "good" | "perfect"
export type ProductWithMapping = {
  id: UUID
  productId: string
  name: string
  questionId: string
  active: boolean
  advisorId: string
  dataSourceId: string
  image: string
  url: string
  price: number | null
  updatedAt: number
  mapping: Record<string, MappingValue>
  data: Record<string, string | number>
  fields: Record<string, string | number>
}

type ProductWithMappingExternal = ProductWithMapping & {
  mapping: Record<string, Record<string, MappingValue>>
  fields: Record<string, string | number>
}

/** Retrieve a page of products with their classification for a question */
const productsWithMapping = async (
  advisorId: UUID,
  dataSourceId: string,
  questionId: string | undefined,
  meta: {
    filter: FilterAPIObj
    query: string
    page: number
    size: number
    token: string
    organisationId: OrganisationId
  }
): Promise<Paginated<ProductWithMapping[]>> => {
  const { token, organisationId, filter, page, size, query } = meta
  const offset = (page - 1) * size
  return API.post<Paginated<ProductWithMappingExternal[]>>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/products?size=${size}&offset=${offset}${
      questionId ? `&questionId=${questionId}` : ""
    }`,
    data: { filter, query },
    token: token,
    organisationId: organisationId,
  }).then((pwms) => ({
    total: pwms.total,
    size: pwms.size,
    offset: pwms.offset,
    results: pwms.results.map((pwm) => {
      return {
        ...pwm,
        data: pwm.fields,
        mapping: (pwm.mapping && pwm.mapping[questionId || ""]) || {},
      }
    }),
  }))
}

/** Retrieve a page of products with their classification */
const updateProductMapping = async (
  advisorId: UUID,
  dataSourceId: string,
  data: {
    productIds: string[]
    answerId: UUID
    classification: MappingValue
  },
  token: string,
  organisationId: OrganisationId
): Promise<{ ok: boolean }> => {
  return API.put<any>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/batch-update`,
    data,
    token,
    organisationId,
  })
}

export type AnswerMappingStat = {
  id: UUID
  buckets: {
    bad: number
    neutral: number
    good: number
    perfect: number
    no_mapping: number
  }
}

export type MappingStats = {
  id: UUID
  answers: AnswerMappingStat[]
  unmapped: number
}

/** Retrieve a the mapping stats for questions and answers */
const getStats = async (
  advisorId: UUID,
  dataSourceId: string,
  token: string,
  organisationId: OrganisationId
): Promise<MappingStats[]> => {
  return API.get<any>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/stats`,
    token,
    organisationId,
  })
}

/** Retrieve a list of columns for a datasource */
const getColumns = async (
  advisorId: UUID,
  dataSourceId: string,
  token: string,
  organisationId: OrganisationId
): Promise<Column[]> => {
  return API.get<any>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/columns`,
    token,
    organisationId,
  })
}

/** Retrieve all product ids by filter */
export type GetProductIdsResponse = { id: UUID }
const getProductIds = async (
  advisorId: UUID,
  dataSourceId: string,
  filter: FilterAPIObj,
  query: string,
  token: string,
  organisationId: OrganisationId
): Promise<UUID[]> => {
  return API.post<Paginated<GetProductIdsResponse[]>>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/products?size=-1&fields=id`,
    token: token,
    data: { filter: filter, query: query },
    organisationId,
  }).then((pwms) => pwms.results.map((value) => value.id))
}

export type PropertyResponse =
  | {
      name: string
      type: ColumnType.TEXT
      values: { name: string; count: number }[]
    }
  | {
      name: string
      type: ColumnType.NUMBER
      min: number
      max: number
    }

/** Retrieve a list of properties for a given column of a datasource */
const getProperties = async (
  advisorId: UUID,
  dataSourceId: string,
  column: Column,
  token: string,
  organisationId: OrganisationId
): Promise<PropertyResponse> => {
  return API.post<any>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/columns`,
    data: { column: column.name, type: column.type },
    token,
    organisationId,
  })
}

export {
  productsWithMapping,
  updateProductMapping,
  getColumns,
  getProperties,
  getStats,
  getProductIds,
}
