import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import useNavigation from "~/hooks/useNavigation"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import Card from "~/components/Card"
import Csv from "~/components/icons/Csv"
import GoogleShopping from "~/components/icons/GoogleShopping"
import TutorialLink from "~/components/TutorialLink"
import Info from "~/components/icons/Info"

const Icon = styled.div`
  max-width: 100%;
  height: 3rem;
  width: 3rem;
  margin: 1rem auto;
`

const Options = styled.div`
  p {
    max-width: 60rem;
  }
`
const DataSourceOptions = styled.div`
  margin-top: 2rem;
  max-width: 60rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;

  .branding {
    display: grid;
    grid-template-columns: 3rem 1fr;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  @media screen and (min-width: 1024px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;

    ${Card} {
      display: grid;
      grid-template-rows: min-content 1fr min-content;
    }
  }

  span {
    font-weight: bold;
  }
`
const CreateDataSource: React.FC<{}> = () => {
  const { t } = useTranslation()
  const { toDataSourceNewData, toNewGoogleShoppingFeed } = useNavigation()
  const { id: advisorId, productLabel } = useAdvisorContext()

  return (
    <Options>
      <h2>{t("productDataset.empty.title", { productLabel })}</h2>
      <p>{t("productDataset.empty.intro1")}</p>
      <p className="callout with-icon">
        <span>
          <Info />
        </span>
        <div>
          <span className="callout--title">Good to know:</span>
          <div className="callout--text">
            <p>The import type you select cannot be changed once configured.</p>
          </div>
        </div>
      </p>

      <DataSourceOptions>
        <Card>
          <div className="branding">
            <Icon>
              <GoogleShopping />
            </Icon>
            <span>Google Shopping feed</span>
          </div>
          <div>
            <strong>Pros</strong>
            <ul className="mt-xs">
              <li>
                Product data such as price, image and specifications are
                automatically synced.
              </li>
              <li>
                Product availability is automatically synced. Out-of-stock
                products will not be advised to customers.
              </li>
              <li>Supports the use of a sale price and sale period.</li>
              <li>
                Especially useful for relatively large and dynamic product
                catalogues (+30 products).
              </li>
            </ul>
            <TutorialLink
              article="catalogue-feed"
              label="Link your product feed"
            />
          </div>
          <button
            className="btn primary is-full-width mt-1"
            onClick={() => toNewGoogleShoppingFeed(advisorId)}
          >
            Select
          </button>
        </Card>
        <Card>
          <div className="branding">
            <Icon style={{ color: "#268756" }}>
              <Csv />
            </Icon>
            <span>CSV</span>
          </div>
          <div>
            <strong>Pros</strong>
            <ul className="mt-xs">
              <li>
                Relatively easy to set up if a Google Shopping feed isn't
                available.
              </li>
            </ul>

            <strong className="mt-1">Cons</strong>
            <ul className="mt-xs">
              <li>
                No automatic syncing of product data: changes in price and
                availability have to be uploaded manually.
              </li>
              <li>Does not support the use of a sale price and sale period.</li>
              <li>Requires a manual maintenance process across your team.</li>
            </ul>
            <TutorialLink
              article="catalogue-csv"
              label="Link your products with a CSV"
            />
          </div>
          <button
            className="btn primary is-full-width mt-1"
            onClick={() => toDataSourceNewData(advisorId)}
          >
            Select
          </button>
        </Card>
      </DataSourceOptions>
    </Options>
  )
}

export default CreateDataSource
