import API from "./api"
import JWT from "~/lib/JWT"
import {
  Account,
  Avatar,
  EmailPreferences,
  Role,
} from "~/store/currentUser/types"
import { Language } from "../i18n"
import { Theme } from "../themes"
import { FeatureFlag } from "~/hooks/useFeatureFlags"
import { OrganisationId } from "./types"

type AccountExternal = {
  id: string
  email: string
  firstName: string
  familyName: string | undefined
  profileImage: Avatar | undefined
  updatedAt: number
  insertedAt: number
  role: Role | undefined
  preferences: AccountPreferences
  emailPreferences: EmailPreferences
}

type AccountPreferences = {
  language?: string
  theme?: string
}

export type Invite = {
  id: string
  email: string
  role: Role
  invitedAt: number
  expired: boolean
  emailPreferences: EmailPreferences
}

const invites = async (
  token: string,
  organisationId: OrganisationId
): Promise<Invite[]> => {
  return API.get<Invite[]>({
    path: "/accounts/invites",
    token,
    organisationId,
  })
}

const updateInvitation = async (
  invite: { id: string; role: Role },
  token: string,
  organisationId: OrganisationId
): Promise<Invite[]> => {
  return API.patch<Invite[]>({
    path: `/accounts/invites/${invite.id}`,
    data: { role: invite.role },
    token,
    organisationId,
  })
}

const revokeInvitation = async (
  invite: { id: string },
  token: string,
  organisationId: OrganisationId
): Promise<{ status: "ok" }> => {
  return API.delete<{ status: "ok" }>({
    path: `/accounts/invites/${invite.id}`,
    token,
    organisationId,
  })
}

/** Retrieve a list of all accounts*/
const all = async (
  token: string,
  organisationId: OrganisationId
): Promise<Account[]> => {
  return API.get<AccountExternal[]>({
    path: "/accounts",
    token,
    organisationId,
  }).then((accounts) =>
    accounts.map((account) => ({
      id: account.id,
      email: account.email,
      firstName: account.firstName,
      familyName: account.familyName || "",
      profileImage: account.profileImage,
      preferences: {
        language: account.preferences.language
          ? Language[account.preferences.language]
          : undefined,
        theme: account.preferences.theme
          ? Theme[account.preferences.theme]
          : undefined,
      },
      emailPreferences: {
        sendPeriodicUsageRecap: account.emailPreferences.sendPeriodicUsageRecap,
      },
      updatedAt: account.updatedAt,
      role: account.role || Role.Contributor,
    }))
  )
}

// /** Retrieve user account */
const get = async (
  token: string,
  organisationId: OrganisationId
): Promise<
  AccountExternal & {
    featureFlags: Record<FeatureFlag, boolean>
    organisationSettings: { impactAnalyticsApiKey: string | null }
    integrationStatus: {
      purchaseTracking: "OK" | "NOT_CONFIGURED" | "ERROR"
      purchaseTrackingTimeConfigured: number | null
    }
  }
> => {
  const { accountId } = JWT.parseValues(token!)

  return API.get<
    AccountExternal & {
      featureFlags: Record<FeatureFlag, boolean>
      organisationSettings: { impactAnalyticsApiKey: string | null }
      integrationStatus: {
        purchaseTracking: "OK" | "NOT_CONFIGURED" | "ERROR"
        purchaseTrackingTimeConfigured: number | null
      }
    }
  >({
    path: "/accounts/" + accountId,
    token,
    organisationId,
  }).then((account) => ({
    ...account,
    familyName: account.familyName || undefined,
  }))
}

/**Update the account of the current user */
const update = async (
  token: string,
  organisationId: OrganisationId,
  account: Pick<AccountExternal, "firstName" | "familyName">
): Promise<AccountExternal> => {
  const { accountId } = JWT.parseValues(token!)

  return API.put<AccountExternal>({
    path: "/accounts/" + accountId,
    data: {
      ...account,
      preferences: {
        language: Language.americanEnglish,
        theme: "light",
      },
    },
    token,
    organisationId,
  })
}

/**Update the account of a specific user user */
const updateAccount = async (
  id: string,
  account: Pick<AccountExternal, "role">,
  token: string,
  organisationId: OrganisationId
): Promise<AccountExternal> => {
  return API.patch<AccountExternal>({
    path: "/accounts/" + id,
    data: account,
    token,
    organisationId,
  })
}

/**Delete an account from an organisation */
const deleteAccount = async (
  id: string,
  token: string,
  organisationId: OrganisationId
): Promise<{ status: "ok" }> => {
  return API.delete<{ status: "ok" }>({
    path: "/accounts/" + id,
    token,
    organisationId,
  })
}

const forgotPasswordRequest = async (email: string) =>
  API.post<{ token: string }>({
    path: "/accounts/reset-password",
    data: {
      email,
    },
    organisationId: null,
  })

type BatchInviteResponse = {
  invited: Invite[]
  notInvited: string[]
}

/** Send an invite to one or multiple users */
const invite = async (
  invites: {
    email: string
    role: string
  }[],
  token: string,
  organisationId: OrganisationId
): Promise<BatchInviteResponse> =>
  API.post<BatchInviteResponse>({
    path: "/accounts/invite",
    data: {
      invites,
    },
    token,
    organisationId,
  })

type InviteResp = { status: "ok"; email: string }
const acceptInvitation = async (inviteToken: string) =>
  API.post<InviteResp>({
    path: "/accounts/accept-invitation",
    data: {
      token: inviteToken,
    },
    organisationId: null,
  })

/** Submit reset token and change password */
const resetPasswordRequest = async (
  email: string,
  token: string,
  password: string,
  passwordConfirmation: string
) =>
  API.post<{ token: string }>({
    path: "/accounts/reset-password-submit",
    data: {
      email,
      token,
      password,
      passwordConfirmation,
    },
    organisationId: null,
  })

/** Create a new account using an invite token */
const newAccountFromInvite = async (
  inviteToken: string,
  account: {
    profileImage: File | undefined
    password: string
    passwordConfirm: string
    firstName: string
    familyName: string
    emailPreferences: EmailPreferences
  }
) => {
  const formData = {
    token: inviteToken,
    password: account.password,
    passwordConfirm: account.passwordConfirm,
    firstName: account.firstName,
    familyName: account.familyName,
    profileImage: "",
    emailPreferences: {
      sendPeriodicUsageRecap: account.emailPreferences.sendPeriodicUsageRecap,
    },
  }

  return API.post<Account>({
    path: `/accounts`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    organisationId: null,
  })
}

/** Update account avatar */
const updateAvatar = async (
  accountId: string,
  profileImage: File,
  crop: {
    x: number
    y: number
    width: number
    height: number
  },
  token: string,
  organisationId: OrganisationId
) => {
  const formData = new FormData()
  formData.append("cropX", `${crop.x}`)
  formData.append("cropY", `${crop.y}`)
  formData.append("cropWidth", `${crop.width}`)
  formData.append("cropHeight", `${crop.height}`)
  formData.append("file", profileImage)
  formData.append("size", "64x64,128x128,256x256")
  return API.post<Account>({
    path: `/accounts/${accountId}/profile-image`,
    data: formData,
    token: token,
    headers: { "Content-Type": "multipart/form-data" },
    organisationId: organisationId,
  })
}

export {
  get,
  update,
  invite,
  forgotPasswordRequest,
  resetPasswordRequest,
  newAccountFromInvite,
  acceptInvitation,
  updateAvatar,
  all,
  invites,
  updateInvitation,
  revokeInvitation,
  updateAccount,
  deleteAccount,
}
