import { useHistory } from "react-router"
import Elm from "./ElmWrapper"
import { DataSource } from "~/types"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { questionLabelColors } from "~/themes"
import Page from "~/components/advisors/Page"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import { useSelector, useStore } from "react-redux"
import { getAdvisor } from "~/store/advisors/selectors"
import { ApplicationState } from "~/store"
import { getAdviceScreen } from "~/store/screens/selectors"

export const Matching: React.FC<{
  advisorId: string
  dataSource: DataSource
}> = (props) => {
  const history = useHistory()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { token: authToken } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const { featureFlags } = useFeatureFlags()

  const { productLabel } = useAdvisorContext()

  function setupPorts(ports) {
    const gotoUrlHandler = function (url) {
      history.push(url)
      ports.urlChanged.send(window.location.href)
    }

    ports.gotoUrl.subscribe(gotoUrlHandler)
    ports.getFromLocal.subscribe(({ key }) => {
      const val = localStorage.getItem(key)
      ports.gotFromLocal.send({ key: key, value: val ? JSON.parse(val) : null })
    })

    ports.storeInLocal.subscribe(({ key, value }) => {
      localStorage.setItem(key, JSON.stringify(value))
    })

    ports.logError.subscribe((errorString) => {
      console.error(errorString)
    })

    const scrollIntoViewHandler = function (htmlId) {
      document.getElementById(htmlId)?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }

    ports.scrollIntoView?.subscribe(scrollIntoViewHandler)

    const urlChangeHandler = function () {
      ports.urlChanged.send(window.location.href)
    }
    // Inform app of browser navigation (the BACK and FORWARD buttons)
    window.addEventListener("popstate", urlChangeHandler)

    // eslint-disable-next-line react-hooks/rules-of-hooks

    return () => {
      ports.gotoUrl.unsubscribe(gotoUrlHandler)
      ports.storeInLocal.unsubscribe()
      ports.getFromLocal.unsubscribe()
      ports.scrollIntoView?.unsubscribe(scrollIntoViewHandler)
      window.removeEventListener("popstate", urlChangeHandler)
    }
  }
  const productCheckEnabled = useSelector(
    (store: ApplicationState) =>
      getAdviceScreen(store, props.advisorId)?.productScopeConfig?.enabled ||
      false
  )

  return (
    <Page maxWidth>
      {/* // @ts-ignore */}
      <Elm
        src={ElmModule.Matching}
        flags={{
          advisorId: props.advisorId,
          dataSource: props.dataSource,
          authToken: authToken,
          apiUrl: apiUrl,
          advisorTitle: "advisorTitle",
          catalogueTitle: productLabel,
          productLabel: productLabel,
          url: window.location.href,
          questionLabelColors: questionLabelColors,
          packageAdviceEnabled: featureFlags.packageAdviceEnabled || false,
          productCheckEnabled:
            featureFlags.pdpIntegrationEnabled && productCheckEnabled,
        }}
        ports={setupPorts}
      />
    </Page>
  )
}
