import { useTranslation } from "react-i18next"

export const HowToResolveMessage: React.FC<{
  translationLocation: "item" | "setup"
  count: number
}> = ({ translationLocation, count }) => {
  const { t } = useTranslation()
  return (
    <div>
      <p>
        <strong>
          {t(
            `productDataset.feed.${translationLocation}.feedback.resolve.intro`,
            { count }
          )}
        </strong>
      </p>
      <ol>
        <li>
          {t(
            `productDataset.feed.${translationLocation}.feedback.resolve.step1`,
            { count }
          )}
        </li>
        <li>
          {t(
            `productDataset.feed.${translationLocation}.feedback.resolve.step2`
          )}
        </li>
        <li>
          {t(
            `productDataset.feed.${translationLocation}.feedback.resolve.step3`
          )}
        </li>
      </ol>
    </div>
  )
}
