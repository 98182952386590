import qs from "qs"
import React, { ReactNode } from "react"
import { useHistory, useLocation } from "react-router-dom"
import ModalWithSidebar from "~/components/ModalWithSidebar"
import { linkWithParam } from "~/lib/URLHelpers"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { camelCase } from "~/lib/String"
import MyAccount from "./MyAccount"
import Security from "./Security"
import CompanySettings from "./CompanySettings"
import Integrations from "./Integrations"
import Users from "./Users"
import Billing from "./Billing"
import useCurrentUser from "~/hooks/useCurrentUser"
import { Account, Role } from "~/store/currentUser/types"
import { Divider, MenuSection } from "~/components/navigation"
import Link from "~/components/icons/Link"
import Settings from "~/components/icons/Settings"
import CopyToClipboard from "../CopyToClipboard"
import FormSection from "../forms/FormSection"
import FormSplit from "../forms/FormSplit"
import { useImpactAnalytics } from "~/hooks/useImpactAnalytics"

export enum SettingRoute {
  MyAccount = "my-account",
  Security = "security",
  CompanySettings = "company-settings",
  Users = "users",
  Billing = "billing",
  integrationsSettings = "integrations-settings",
  ApiKeys = "api-keys",
}

const myAccount = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
      clipRule="evenodd"
    />
  </svg>
)
const apiKeyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
    />
  </svg>
)

const users = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
  </svg>
)
type LinkObj = {
  icon: JSX.Element
  ref: SettingRoute
}

const navigation = (
  currentUser: Account,
  currentSetting: string,
  navigate: (setting: string) => void,
  apiKey: string | undefined,
  t: TFunction
) => {
  const nav = [
    {
      name: "me",
      accessLevel: Role.Contributor,
      links: [
        { icon: myAccount, ref: SettingRoute.MyAccount },
        // SettingRoute.Security,
      ],
    },
    {
      name: "company",
      accessLevel: Role.Admin,
      links: [
        { icon: <Settings />, ref: SettingRoute.CompanySettings },
        { icon: users, ref: SettingRoute.Users },
        { icon: <Link />, ref: SettingRoute.integrationsSettings },
        apiKey ? { icon: apiKeyIcon, ref: SettingRoute.ApiKeys } : null,
      ].filter((a): a is LinkObj => Boolean(a)),
    },
  ]
  return (
    <>
      {nav.map((category) => {
        if (
          category.accessLevel === Role.Admin &&
          currentUser.role !== Role.Admin
        ) {
          return null
        } else {
          return (
            <nav className="nav" key={category.name}>
              <MenuSection>{category.name}</MenuSection>
              {category.links.map((link) => (
                <div
                  className={`menu-link has-icon-left ${
                    link.ref === currentSetting ? "active" : ""
                  }`}
                  key={link.ref}
                  onClick={() => navigate(link.ref)}
                >
                  <span className="icon">{link.icon}</span>
                  <span className="label">
                    {t(`settings.${camelCase(link.ref)}.navLink`)}
                  </span>
                </div>
              ))}
              <Divider />
            </nav>
          )
        }
      })}
    </>
  )
}

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here")
}

const modalContent = (
  settingRoute: SettingRoute,
  account: Account,
  apiKey: string | undefined
): ReactNode => {
  switch (settingRoute) {
    case SettingRoute.MyAccount:
      return <MyAccount account={account} />
    case SettingRoute.Security:
      return <Security account={account} />
    case SettingRoute.CompanySettings:
      return <CompanySettings />
    case SettingRoute.Users:
      return <Users />
    case SettingRoute.Billing:
      return <Billing />
    case SettingRoute.integrationsSettings:
      return <Integrations />
    case SettingRoute.ApiKeys:
      return apiKey ? <ApiKeys apiKey={apiKey} /> : null
    default:
      return assertUnreachable(settingRoute)
  }
}

const ApiKeys = ({ apiKey }) => {
  return (
    <div>
      <FormSection>
        <h2>API keys</h2>
      </FormSection>
      <FormSplit />
      <label className="form-field__label mt-2">Impact Analytics API key</label>
      <p className="subtle-text">
        This API key is used to send purchase events to Aiden.
      </p>
      <div className="form-field__input-wrapper has-button-right">
        <input value={apiKey} onFocus={(event) => event.target.select()} />
        <CopyToClipboard value={apiKey} />
      </div>
    </div>
  )
}

export default () => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const settingRoute = qs.parse(
    location.search.substring(1, location.search.length)
  )["settings"] as string
  const { currentUser } = useCurrentUser()

  const { apiKey } = useImpactAnalytics()

  const navigate = (setting) =>
    history.replace(
      linkWithParam(location.pathname, location.search, "settings", setting)
    )

  return settingRoute ? (
    <ModalWithSidebar
      onClose={() => navigate(null)}
      left={<>{navigation(currentUser!, settingRoute, navigate, apiKey, t)}</>}
      right={modalContent(settingRoute as SettingRoute, currentUser!, apiKey)}
    />
  ) : null
}
