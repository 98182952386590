import { DataSourceUpdate } from "~/api/dataSources"
import { Cross } from "~/components/icons"
import CheckCircle from "~/components/icons/CheckCircle"
import Warning from "~/components/icons/Warning"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import { IssuesTitle } from "../IssuesTitle"
import { HowToResolveMessage } from "./HowToResolve"
import { ProductIds } from "./New"
import UpdateListItem from "./UpdateListItem"

const TextAnchor = styled.span`
  cursor: pointer;
  &,
  &:visited {
    color: ${(p) => p.theme.secondary};
    text-decoration: underline;
  }
  &:hover {
    text-decoration: underline;
  }
`

const Icn = styled.span<{ status: string }>`
  ${(p) => {
    switch (p.status) {
      case "COMPLETED":
        return css`
          color: ${p.theme.success};
        `
      case "FAILED":
        return css`
          color: ${p.theme.error};
        `
      case "WARNINGS":
        return css`
          color: ${p.theme.warning};
        `
    }
  }}
`
const Columns = styled.ul`
  display: grid;
  grid-template-columns:
    minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr)
    minmax(min-content, 1fr);
  column-gap: 0.5rem;
  margin-top: 0;
  box-sizing: border-box;
  li {
    margin: 0;
    hyphens: auto;
    padding-right: 1rem;
  }
`

const UpdateInformation: React.FC<{
  update: DataSourceUpdate
}> = ({ update }) => {
  const { t } = useTranslation()
  return (
    <>
      {" "}
      {update.changeSummary && (
        <ul>
          <li>
            {t("productDataset.feed.item.updated", {
              amount: update.changeSummary.updated || 0,
            })}
          </li>
          <li>
            {t("productDataset.feed.item.added", {
              amount: update.changeSummary.added || 0,
            })}
          </li>
          <li>
            {t("productDataset.feed.item.removed", {
              amount: update.changeSummary.removed || 0,
            })}
          </li>
          {(update.changeSummary.failed || 0) > 0 && (
            <li>
              {t("productDataset.feed.item.failed", {
                amount: update.changeSummary.failed || 0,
              })}
            </li>
          )}
        </ul>
      )}
      <CollapsableColumns
        columns={update.columnsAdded}
        label={t("productDataset.feed.item.columnsAdded")}
      />
      <CollapsableColumns
        columns={update.columnsRemoved}
        label={t("productDataset.feed.item.columnsRemoved")}
      />
    </>
  )
}

const UpdateRecord: React.FC<{
  update: DataSourceUpdate
  dataSourceLabel: string
  productLabel: string
  isOpen?: boolean
}> = ({ update, productLabel, isOpen = false, dataSourceLabel }) => {
  const { t } = useTranslation()
  const total = update.changeSummary?.total || 0
  const failedAmount = update.changeSummary?.failed || 0
  const issueCount = update.warnings.length || 1

  return (
    <UpdateListItem
      isOpen={isOpen}
      icon={
        <Icn status={update.status}>
          {update.status === "COMPLETED" ? (
            <CheckCircle />
          ) : update.status === "FAILED" ? (
            <Cross />
          ) : update.status === "WARNINGS" ? (
            <Warning />
          ) : (
            <></>
          )}
        </Icn>
      }
      status={
        update.status === "WARNINGS" || update.status === "FAILED"
          ? "warning"
          : "ok"
      }
      label={
        update.status === "COMPLETED" ? (
          <Trans i18nKey="productDataset.feed.item.header.success">
            We've checked <strong>{{ total }}</strong> {{ productLabel }}
          </Trans>
        ) : (
          <Trans i18nKey="productDataset.feed.item.header.warning">
            {{ amount: failedAmount }} of <strong>{{ total }}</strong>{" "}
            {{ productLabel }} could not be updated
          </Trans>
        )
      }
      timeStamp={update.completedAt}
    >
      <UpdateInformation update={update} />

      {(update.status === "WARNINGS" || update.status === "FAILED") && (
        <HowToResolveMessage translationLocation="setup" count={issueCount} />
      )}
      {update.status === "FAILED" && (
        <>
          <IssuesTitle issueCount={1} />
          {update.errorCode !== null ? (
            <p>
              {t("productDataset.feed.item.feedback." + update.errorCode, {
                dataSourceLabel,
              })}
            </p>
          ) : (
            <p>{t("productDataset.feed.item.feedback.unknownError")}</p>
          )}
        </>
      )}

      {update.warnings.length > 0 && (
        <>
          <IssuesTitle issueCount={issueCount} />
          <ul>
            {update.warnings.map((problem, i) => {
              const expected = t(
                "productDataset.feed.item.feedback.dataTypesExpected." +
                  problem.expected
              )

              return (
                problem.products.length > 0 &&
                (problem.error === "UNEXPECTED_TYPE" ? (
                  <li key={i} className="issues-list--item">
                    {t("productDataset.feed.item.feedback.intro", {
                      column: problem.column,
                    })}{" "}
                    <Trans i18nKey="productDataset.feed.item.feedback.expectedActual">
                      A <strong>{{ expected }}</strong>
                      was expected but it contained{" "}
                      <strong>
                        {{
                          actual: problem.actual
                            .map((a) =>
                              t(
                                "productDataset.feed.item.feedback.dataTypesActual." +
                                  a
                              )
                            )
                            .join(", "),
                        }}
                      </strong>
                    </Trans>
                    <div>
                      <ProductIds
                        ids={problem.products.map((p) => p.name)}
                        showCount={10}
                      />
                    </div>
                  </li>
                ) : (
                  <li key={i} className="issues-list--item">
                    {t("productDataset.feed.item.feedback." + problem.error, {
                      column: problem.column,
                      dataSourceLabel,
                    })}
                    <div>
                      <ProductIds
                        ids={problem.products.map((p) => p.name)}
                        showCount={10}
                      />
                    </div>
                  </li>
                ))
              )
            })}
          </ul>
        </>
      )}
    </UpdateListItem>
  )
}

const CollapsableColumns = (props: { columns: string[]; label: string }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const alwaysVisibleColumns = props.columns.slice(0, 12)
  const collapsedColumns = props.columns.slice(12)

  return props.columns.length > 0 ? (
    <>
      <strong style={{ marginBottom: 0 }}>{props.label}</strong>
      <Columns>
        {alwaysVisibleColumns.map((c, i) => (
          <li key={c + i}>{c}</li>
        ))}
        {open && collapsedColumns.map((c, i) => <li key={c + i}>{c}</li>)}
        {collapsedColumns.length > 0 && (
          <TextAnchor onClick={() => setOpen(!open)}>
            {open ? t("general.showLess") : t("general.showMore")}
          </TextAnchor>
        )}
      </Columns>
    </>
  ) : null
}

export default UpdateRecord
