import Label from "~/components/forms/Label"
import React, { useState } from "react"
import styled from "styled-components"
import Input from "./Input"
import Visibility from "~/components/icons/Visibility"
import VisibilityOff from "~/components/icons/VisibilityOff"
import MarkdownTextarea, { FormatOptions } from "~/components/MarkdownTextarea"

type InputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "onChange" | "label"
> & {
  label: React.ReactNode
  value: string
  placeholder?: string
  password?: boolean
  hasError?: boolean
  disabled?: boolean
  showInput?: boolean
  markdownOpts?: FormatOptions
  onBlur?: (value: string) => void
  onFocus?: () => void
  onChange: (value: string) => void
  type?: "text" | "email" | "password"
  labelExtra?: React.ReactNode
}

export const InputWrapper = styled.div`
  position: relative;
`
const InputCharm = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  cursor: pointer;
`

const Field: React.FC<InputProps> = (props) => {
  const {
    label,
    value,
    placeholder,
    onChange,
    type = "text",
    showInput = true,
    markdownOpts,
  } = props
  const [hidden, setHidden] = useState(type === "password")
  const inputType = type === "password" ? (hidden ? "password" : "text") : type
  const [focussed, setFocussed] = useState(false)

  return (
    <div className="form-field">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Label disabled={props.disabled} focussed={focussed}>
          {label}
        </Label>
        {props.labelExtra}
      </div>
      {showInput && (
        <InputWrapper>
          {markdownOpts ? (
            <MarkdownTextarea
              text={value}
              toolbar={markdownOpts}
              disabled={props.disabled}
              hasError={props.hasError}
              onChange={(e) => !props.disabled && onChange(e)}
            />
          ) : (
            <>
              <Input
                type={inputType}
                autoComplete={type === "password" ? "on" : undefined}
                value={value}
                hasError={props.hasError}
                disabled={props.disabled}
                onFocus={() => {
                  setFocussed(true)
                  props.onFocus && props.onFocus()
                }}
                onBlur={() => {
                  setFocussed(false)
                  props.onBlur && props.onBlur(value)
                }}
                onChange={(e) => !props.disabled && onChange(e.target.value)}
                fullWidth
                placeholder={placeholder}
              />
              {type === "password" && (
                <InputCharm onClick={() => setHidden(!hidden)}>
                  {hidden ? <VisibilityOff /> : <Visibility />}
                </InputCharm>
              )}
            </>
          )}
        </InputWrapper>
      )}
    </div>
  )
}

export default Field
