import React, { ReactNode, useCallback, useEffect, useState } from "react"
import FieldGroup from "~/components/forms/FieldGroup"
import FormSection from "~/components/forms/FormSection"
import Form from "~/components/forms/Form"
import Button from "~/components/Button"
import LoadingSpinner from "~/components/Loading"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import { useAuth } from "~/context/auth"
import organisations from "~/api/organisations"
import ToggleSwitch from "../ToggleSwitch"
import FormSplit from "../forms/FormSplit"
import styled from "styled-components"
import Card from "~/components/Card"
import {
  FeatureFlag,
  FeatureFlags,
  useFeatureFlags,
} from "~/hooks/useFeatureFlags"
import { SingleSelect } from "../selection"
import { useProductFieldAvailability } from "./useProductFieldAvailability"
import useNavigation from "~/hooks/useNavigation"
import { Link } from "react-router-dom"
import { FieldAvailability } from "~/api/integrations"
import Warning from "../icons/Warning"
import sortBy from "lodash/sortBy"
import useNotifications from "~/hooks/useNotifications"
import TutorialLink from "~/components/TutorialLink"

const IntegrationCard = styled(Card)`
  display: grid;
  width: 100%;
  gap: 1.5rem;

  img {
    max-height: 3rem;
    display: block;
    /* align-self: center; */
  }
  .integration--toggle {
    display: flex;
    justify-content: space-between;
    /* justify-self: end; */
  }
  .integration--info {
    display: grid;
    width: 100%;
    /* grid-template-columns: 12rem auto; */
    gap: 1rem;
    p {
      margin: 0;
      font-size: 0.875rem;
      color: ${(p) => p.theme.shade5};
    }
  }
`

const Integrations = () => {
  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const [squeezelyIntegration, setSqueezelyIntegration] = useState<{
    enabled: boolean
    productIdField: string | null
  }>({
    enabled: false,
    productIdField: null,
  })

  const [googleTagManagerIntegration, setGoogleTagManagerIntegration] =
    useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

  const [googleAnalyticsIntegration, setGoogleAnalyticsIntegration] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const [bloomReachIntegration, setBloomReachIntegration] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const [customIntegration, setCustomIntegration] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })
  const [loading, setLoading] = useState(true)
  const { notify } = useNotifications()
  const { isChecking, checkField, clearField, advisorsWithMissingField } =
    useProductFieldAvailability(token, organisationId)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(false)
  const { featureFlags } = useFeatureFlags()

  const anyIntegrationsEnabled =
    featureFlags.bloomreachEnabled ||
    featureFlags.customIntegrationEnabled ||
    featureFlags.bloomreachEnabled ||
    featureFlags.googleAnalyticsEnabled ||
    featureFlags.squeezelyEnabled

  const checkMatchingAdvisorsForField = useCallback((value: string | null) => {
    if (value === null) {
      clearField()
    } else {
      clearField()
      checkField(value)
    }
  }, [])

  const save = useCallback(() => {
    if (anyIntegrationsEnabled) {
      let isMounted = true
      setError(false)
      setSaving(true)
      const justResolve = Promise.resolve(undefined)

      Promise.all([
        featureFlags.squeezelyEnabled
          ? organisations.updateSqueezelyIntegration(token!, organisationId, {
              enabled: squeezelyIntegration.enabled,
              productIdField: squeezelyIntegration.productIdField,
            })
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.updateGoogleTagManagerIntegration(
              token!,
              organisationId,
              {
                enabled: googleTagManagerIntegration.enabled,
              }
            )
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.updateGoogleAnalyticsIntegration(
              token!,
              organisationId,
              {
                enabled: googleAnalyticsIntegration.enabled,
              }
            )
          : justResolve,
        featureFlags.customIntegrationEnabled
          ? organisations.updateCustomIntegration(token!, organisationId, {
              enabled: customIntegration.enabled,
            })
          : justResolve,
        featureFlags.bloomreachEnabled
          ? organisations.updateBloomReachIntegration(token!, organisationId, {
              enabled: bloomReachIntegration.enabled,
            })
          : justResolve,
      ])
        .then(() => {
          notify({
            text: "Changes saved!",
            type: "success",
          })
        })
        .catch(() => {
          if (isMounted) setError(true)
          notify({
            text: "Something went wrong. Please try again or contact support if the problem persists",
            type: "error",
          })
        })
        .finally(() => {
          if (isMounted) setSaving(false)
        })

      return () => {
        isMounted = false
      }
    }
  }, [
    anyIntegrationsEnabled,
    featureFlags.bloomreachEnabled,
    featureFlags.squeezelyEnabled,
    featureFlags.googleAnalyticsEnabled,
    featureFlags.customIntegrationEnabled,
    squeezelyIntegration,
    googleTagManagerIntegration,
    googleAnalyticsIntegration,
    customIntegration,
    bloomReachIntegration,
    organisationId,
    token,
  ])

  const ifIntegrationsEnabled =
    <T,>(fn: (arg: T) => void, ff: FeatureFlag) =>
    (value: T) => {
      if (featureFlags[ff] || false) fn(value)
    }

  const setSqueezely = ifIntegrationsEnabled(
    setSqueezelyIntegration,
    FeatureFlag.squeezelyEnabled
  )
  const setGoogleTagManager = ifIntegrationsEnabled(
    setGoogleTagManagerIntegration,
    FeatureFlag.googleAnalyticsEnabled
  )
  const setGoogleAnalytics = ifIntegrationsEnabled(
    setGoogleAnalyticsIntegration,
    FeatureFlag.googleAnalyticsEnabled
  )
  const setCustom = ifIntegrationsEnabled(
    setCustomIntegration,
    FeatureFlag.customIntegrationEnabled
  )
  const setBloomReach = ifIntegrationsEnabled(
    setBloomReachIntegration,
    FeatureFlag.bloomreachEnabled
  )

  useEffect(() => {
    let isMounted = true
    if (!anyIntegrationsEnabled) {
      setLoading(false)
      return () => {
        isMounted = false
      }
    }
    const justResolve = Promise.resolve(undefined)
    if (token) {
      Promise.all([
        featureFlags.squeezelyEnabled
          ? organisations.getSqueezelyIntegration(token, organisationId)
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.getGoogleTagManagerIntegration(token, organisationId)
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.getGoogleAnalyticsIntegration(token, organisationId)
          : justResolve,
        featureFlags.customIntegrationEnabled
          ? organisations.getCustomIntegration(token, organisationId)
          : justResolve,
        featureFlags.bloomreachEnabled
          ? organisations.getBloomReachIntegration(token, organisationId)
          : justResolve,
      ])
        .then(
          ([
            squeezelyIntegration,
            googleTagManagerIntegration,
            googleAnalyticsIntegration,
            customIntegration,
            bloomReachIntegration,
          ]) => {
            if (isMounted) {
              if (squeezelyIntegration) {
                setSqueezelyIntegration({
                  enabled: squeezelyIntegration.enabled,
                  productIdField: squeezelyIntegration.productIdField,
                })
                checkMatchingAdvisorsForField(
                  squeezelyIntegration.productIdField
                )
              }
              if (googleTagManagerIntegration) {
                setGoogleTagManagerIntegration({
                  enabled: googleTagManagerIntegration.enabled,
                })
              }
              if (googleAnalyticsIntegration) {
                setGoogleAnalyticsIntegration({
                  enabled: googleAnalyticsIntegration.enabled,
                })
              }
              if (customIntegration) {
                setCustomIntegration({
                  enabled: customIntegration.enabled,
                })
              }
              if (bloomReachIntegration) {
                setBloomReachIntegration({
                  enabled: bloomReachIntegration.enabled,
                })
              }
            }
          }
        )
        .catch((_) => {
          if (isMounted) setError(true)
        })
        .finally(() => {
          if (isMounted) setLoading(false)
        })
    }
    return () => {
      isMounted = false
    }
  }, [token, organisationId])

  const formList = [
    {
      enabled: featureFlags.googleAnalyticsEnabled,
      category: "analytics",
      form: googleAnalyticsForm(
        googleTagManagerIntegration,
        setGoogleTagManager,
        featureFlags,
        googleAnalyticsIntegration,
        setGoogleAnalytics
      ),
    },
    {
      enabled: featureFlags.squeezelyEnabled,
      category: "cdp",
      form: squeezelyForm(
        squeezelyIntegration,
        setSqueezely,
        featureFlags,
        checkMatchingAdvisorsForField,
        isChecking,
        advisorsWithMissingField
      ),
    },
    {
      category: "cdp",
      enabled: featureFlags.bloomreachEnabled,
      form: bloomreachForm(featureFlags, bloomReachIntegration, setBloomReach),
    },
    {
      category: "custom",
      enabled: featureFlags.customIntegrationEnabled,
      form: customIntegrationForm(customIntegration, setCustom, featureFlags),
    },
  ]

  const enabledCdpFormList = formList.filter(
    (a) => a.enabled && a.category === "cdp"
  )
  const disabledCdpFormList = formList.filter(
    (a) => !a.enabled && a.category === "cdp"
  )
  const enabledAnalyticsFormList = formList.filter(
    (a) => a.enabled && a.category === "analytics"
  )
  const disabledAnalyticsFormList = formList.filter(
    (a) => !a.enabled && a.category === "analytics"
  )
  const customFormList = formList.filter((a) => a.category === "custom")

  return (
    <Form
      onSubmit={save}
      actions={
        !loading && (
          <Button isLoading={saving} primary disabled={!anyIntegrationsEnabled}>
            {saving ? <LoadingSpinner size="small" /> : "Save"}
          </Button>
        )
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormSection>
            <h2>{t("settings.integrationsSettings.title")}</h2>
          </FormSection>
          <FormSplit />
          {anyIntegrationsEnabled ? (
            <p>{t("settings.integrationsSettings.text")}</p>
          ) : (
            <div className="hints mb-2">
              <p>
                This feature is available on the Professional license or higher.
              </p>
              <TutorialLink
                article="custom-integration"
                label="Gather customer insights with your CRM/CDP"
              />
            </div>
          )}
          <FormSection>
            <FieldGroup className="vertical-group">
              <h2 style={{ letterSpacing: "-0.025em" }} className="mb-0 mt-1">
                Analytics
              </h2>
              {enabledAnalyticsFormList.map(({ form }) => form)}
              {disabledAnalyticsFormList.map(({ form }) => form)}
              <h2 style={{ letterSpacing: "-0.025em" }} className="mb-0 mt-1">
                Customer data platform
              </h2>
              {enabledCdpFormList.map(({ form }) => form)}
              {disabledCdpFormList.map(({ form }) => form)}
              <h2 style={{ letterSpacing: "-0.025em" }} className="mb-0 mt-1">
                Custom integration
              </h2>
              {customFormList.map(({ form }) => form)}
            </FieldGroup>
          </FormSection>
          {error && <HelpText hasError>{t("errors.error")}</HelpText>}
        </>
      )}
    </Form>
  )
}

function googleAnalyticsForm(
  googleTagManagerIntegration: { enabled: boolean },
  setGoogleTagManager: (
    value: React.SetStateAction<{ enabled: boolean }>
  ) => void,
  featureFlags: FeatureFlags,
  googleAnalyticsIntegration: { enabled: boolean },
  setGoogleAnalytics: (
    value: React.SetStateAction<{ enabled: boolean }>
  ) => void
): ReactNode {
  return (
    <>
      <IntegrationOption
        enabled={googleTagManagerIntegration.enabled}
        onChange={(value: boolean) => {
          setGoogleTagManager({ enabled: value })
        }}
        integrationsEnabled={featureFlags.googleAnalyticsEnabled}
        icon={
          <img
            src={"/integrations/google_tag_manager.png"}
            alt={"Google Tag Manager logo"}
          />
        }
        integrationInfo={
          <>
            <p>
              Most websites use Google Tag Manager to connect to Google
              Analytics. This integration sends events from your Aiden apps to
              GTM. After enabling it, simply follow the tutorial to use the
              events in Google Analytics.
            </p>
            <TutorialLink
              article="google-tag-manager"
              label="Setup Google Tag Manager and Google Analytics"
            />
          </>
        }
      />

      <IntegrationOption
        enabled={googleAnalyticsIntegration.enabled}
        onChange={(value: boolean) => setGoogleAnalytics({ enabled: value })}
        integrationsEnabled={featureFlags.googleAnalyticsEnabled}
        icon={
          <img
            src={"/integrations/google_analytics.svg"}
            alt={"Google Analytics logo"}
          />
        }
        integrationInfo={
          <>
            <p>
              Some website use the gtag.js snippet to connect to Google
              Analytics. If you have the gtag.js snippet set up, enable this
              integration to send events from your Aiden apps directly to Google
              Analytics.
            </p>
            <TutorialLink
              article="google-analytics"
              label="Link with Google Analytics"
            />
          </>
        }
      />
    </>
  )
}

function customIntegrationForm(
  customIntegration: { enabled: boolean },
  setCustom: (value: React.SetStateAction<{ enabled: boolean }>) => void,
  featureFlags: FeatureFlags
): ReactNode {
  return (
    <IntegrationOption
      enabled={customIntegration.enabled}
      onChange={(value: boolean) => {
        setCustom({ enabled: value })
      }}
      integrationsEnabled={featureFlags.customIntegrationEnabled}
      icon={
        <div className="horizontal-group">
          <img src={"/integrations/custom.svg"} alt={"Custom integration"} />
          <strong style={{ fontSize: "1.25rem" }}>Build your own</strong>
        </div>
      }
      integrationInfo={
        <>
          <p>
            Use JavaScript to create your own custom integration to any
            platform. How? Learn more about it in the tutorial.
          </p>
          <TutorialLink
            article="custom-integration"
            label="Gather customer insights with your CRM/CDP"
          />
        </>
      }
    />
  )
}

function bloomreachForm(
  featureFlags: FeatureFlags,
  bloomReachIntegration: { enabled: boolean },
  setBloomReach: (value: React.SetStateAction<{ enabled: boolean }>) => void
): ReactNode | null {
  return !featureFlags.bloomreachEnabled ? null : (
    <IntegrationOption
      enabled={bloomReachIntegration.enabled}
      onChange={(value: boolean) => setBloomReach({ enabled: value })}
      integrationsEnabled={featureFlags.bloomreachEnabled}
      icon={
        <img src={"/integrations/bloomreach.svg"} alt={"Bloomreach logo"} />
      }
      integrationInfo={
        <p>
          Send data from your guided selling apps directly to Bloomreach. Events
          will be sent to Bloomreach Engagement if you have their snippet setup
          on your website.
        </p>
      }
    />
  )
}

function squeezelyForm(
  squeezelyIntegration: { enabled: boolean; productIdField: string | null },
  setSqueezely: (
    value: React.SetStateAction<{
      enabled: boolean
      productIdField: string | null
    }>
  ) => void,
  featureFlags: FeatureFlags,
  checkMatchingAdvisorsForField: (value: string | null) => void,
  isChecking: boolean,
  advisorsWithMissingField: FieldAvailability[] | undefined
): ReactNode {
  return (
    <IntegrationOption
      enabled={squeezelyIntegration.enabled}
      onChange={(value: boolean) => {
        setSqueezely({
          ...squeezelyIntegration,
          enabled: value,
        })
      }}
      integrationsEnabled={featureFlags.squeezelyEnabled}
      icon={<img src={"/integrations/squeezely.png"} alt={"Squeezely logo"} />}
      integrationInfo={
        <>
          <p>
            Send data from your guided selling apps directly to Squeezely for
            website personalisation and e-mail follow-ups. No need to configure
            anything, just make sure you have Squeezely set up on your website!
          </p>
          <TutorialLink article="squeezely" label="Pair with Squeezely" />
        </>
      }
    >
      <div className="form-field">
        <label className="form-field__label">
          Product property to use as Squeezely ID
        </label>

        <p className="subtle-text mt-0">
          Make sure your Aiden catalogues contain the product ID that is used by
          Squeezely, then select that property here. Whenever a product event is
          triggered, Aiden will send the selected ID to Squeezely for an instant
          match.
        </p>

        <div>
          <SingleSelect
            value={squeezelyIntegration.productIdField}
            onSelect={(value: string) => {
              checkMatchingAdvisorsForField(value)
              setSqueezely({
                enabled: true,
                productIdField: value,
              })
            }}
            options={[
              { label: "id", value: null },
              { label: "sku", value: "sku" },
              { label: "gtin", value: "gtin" },
              { label: "ean", value: "ean" },
              { label: "mpn", value: "mpn" },
              { label: "squeezely_id", value: "squeezely_id" },
            ]}
          />
        </div>
        <p>
          {isChecking ? (
            <span>
              <LoadingSpinner />
            </span>
          ) : null}
          {advisorsWithMissingField?.length ? (
            <div className="">
              <div className="toast no-animation warning">
                <p className="font-bold">
                  <span style={{ marginRight: "0.25rem" }}>
                    <Warning />
                  </span>
                  The property '{squeezelyIntegration.productIdField}' is not
                  available in {advisorsWithMissingField?.length || 0} apps.
                </p>
                <p className="">
                  Using this property will mean that the Squeezely integration
                  will not function properly for those apps. Make sure the '
                  {squeezelyIntegration.productIdField}' property is included in
                  each of your apps' catalogues.
                </p>
              </div>
              <AdvisorsWithoutFieldOverview
                apps={advisorsWithMissingField || []}
                productIdField={squeezelyIntegration.productIdField}
              />
            </div>
          ) : null}
        </p>
      </div>
    </IntegrationOption>
  )
}

function AdvisorsWithoutFieldOverview(props: {
  apps: FieldAvailability[]
  productIdField: string | null
}) {
  const { apps, productIdField } = props
  if (apps.length === 0 || productIdField === null) return null

  return (
    <div>
      <>
        {/* <h3 className="h4">
          Squeezely integration breaks for these <strong>{apps.length}</strong>{" "}
          apps:
        </h3> */}

        <CollapseList items={apps} field={productIdField} max={5} />
      </>
    </div>
  )
}

function CollapseList(props: {
  items: FieldAvailability[]
  max: number
  field: string | undefined
}) {
  const { map } = useNavigation()
  const [isOpen, setOpen] = useState(false)
  // const items = props.items.sort((a, b) =>
  //   a.advisor.name.toLowerCase() > b.advisor.name.toLowerCase() ? 1 : -1
  // )
  const items = sortBy(props.items, (a) => [
    !a.isLive,
    a.advisor.name.toLowerCase(),
  ])
  const toShow = isOpen ? items : items.slice(0, props.max)
  return (
    <div>
      <table className="mb-1">
        <tr>
          <th className="text-align-left">
            Apps without '{props.field}' property
          </th>{" "}
          <th className="text-align-left">Status</th>
        </tr>
        {toShow.map((result) => (
          <tr>
            <td>
              <Link
                to={map.catalogueProductCatalogue(result.advisor.id)}
                className="link"
              >
                {result.advisor.name}
              </Link>
            </td>
            <td>
              <div className="horizontal-group has-centered-items has-small-gap">
                <span>{result.isLive ? "Live" : "Draft"}</span>
                {result.isLive ? (
                  <div className="status-indicator is-green"></div>
                ) : (
                  <div className="status-indicator"></div>
                )}
              </div>
            </td>
          </tr>
        ))}
      </table>

      {items.length > props.max ? (
        isOpen ? (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setOpen(!isOpen)}
          >
            Show less
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setOpen(!isOpen)}
          >
            Show {items.length - props.max} more
          </button>
        )
      ) : null}
    </div>
  )
}

const IntegrationOption: React.FC<{
  enabled: boolean
  integrationsEnabled: boolean
  icon: ReactNode
  integrationInfo: ReactNode
  onChange: (value: boolean) => void
}> = (props) => {
  return (
    <IntegrationCard isDisabled={!props.integrationsEnabled}>
      <div className="integration--toggle">
        {props.icon}
        <ToggleSwitch
          label={""}
          onChange={props.onChange}
          toggledOn={props.enabled}
          disabled={!props.integrationsEnabled}
        />
      </div>
      <div className="integration--info">{props.integrationInfo}</div>

      {props.children ? (
        <div className="settings-form">
          <div className="settings-form--divider" />
          <div
            className={`settings-form--row ${
              props.enabled ? "" : "is-disabled"
            }`}
          >
            {props.children}
          </div>
        </div>
      ) : null}
    </IntegrationCard>
  )
}

export default Integrations
