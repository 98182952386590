import API from "./api"
import JWT from "~/lib/JWT"
import { Language } from "~/i18n"
import { OrganisationId } from "./types"

type OrganisationExternal = {
  id: string
  name: string
  language: Language
}

type IntegrationsExternal = {
  integrations: {
    squeezely: boolean
  }
}

const get = async (
  token: string,
  organisationId: OrganisationId
): Promise<OrganisationExternal> => {
  // const { organisationId } = JWT.parseValues(token!)

  return API.get<OrganisationExternal>({
    path: "/organisations/" + organisationId.orgId,
    token,
    organisationId,
  })
}

const update = async (
  token: string,
  organisationId: OrganisationId,
  organisation: Omit<OrganisationExternal, "id">
): Promise<OrganisationExternal> => {
  // const { organisationId } = JWT.parseValues(token!)

  return API.put<OrganisationExternal>({
    path: "/organisations/" + organisationId.orgId,
    data: organisation,
    token,
    organisationId,
  })
}

const getSqueezelyIntegration = async (
  token: string,
  organisationId: OrganisationId
): Promise<{ enabled: boolean; productIdField: string | null }> => {
  return API.get<{ enabled: boolean; productIdField: string | null }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/squeezely",
    token,
    organisationId,
  })
}

const getBloomReachIntegration = async (
  token: string,
  organisationId: OrganisationId
): Promise<{ enabled: boolean; productIdField: string | null }> => {
  return API.get<{ enabled: boolean; productIdField: string | null }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/bloomreach",
    token,
    organisationId,
  })
}

const getGoogleTagManagerIntegration = async (
  token: string,
  organisationId: OrganisationId
): Promise<{ enabled: boolean }> => {
  return API.get<{ enabled: boolean }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/google-tag-manager",
    token,
    organisationId,
  })
}

const getGoogleAnalyticsIntegration = async (
  token: string,
  organisationId: OrganisationId
): Promise<{ enabled: boolean }> => {
  return API.get<{ enabled: boolean; productIdField: string | null }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/google-analytics",
    token,
    organisationId,
  })
}

const getCustomIntegration = async (
  token: string,
  organisationId: OrganisationId
): Promise<{ enabled: boolean }> => {
  return API.get<{ enabled: boolean }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/custom",
    token,
    organisationId,
  })
}

const updateSqueezelyIntegration = async (
  token: string,
  organisationId: OrganisationId,
  data: { enabled: boolean; productIdField: string | null }
): Promise<{ enabled: boolean; productIdField: string | null }> => {
  return API.put<{ enabled: boolean; productIdField: string | null }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/squeezely",
    data: data,
    token,
    organisationId,
  })
}

const updateBloomReachIntegration = async (
  token: string,
  organisationId: OrganisationId,
  data: { enabled: boolean }
): Promise<{ enabled: boolean }> => {
  return API.put<{ enabled: boolean }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/bloomreach",
    data: data,
    token,
    organisationId,
  })
}

const updateGoogleTagManagerIntegration = async (
  token: string,
  organisationId: OrganisationId,
  data: { enabled: boolean }
): Promise<{ enabled: boolean }> => {
  return API.put<{ enabled: boolean }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/google-tag-manager",
    data: data,
    token,
    organisationId,
  })
}

const updateGoogleAnalyticsIntegration = async (
  token: string,
  organisationId: OrganisationId,
  data: { enabled: boolean }
): Promise<{ enabled: boolean }> => {
  return API.put<{ enabled: boolean }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/google-analytics",
    data: data,
    token,
    organisationId,
  })
}

const updateCustomIntegration = async (
  token: string,
  organisationId: OrganisationId,
  data: { enabled: boolean }
): Promise<{ enabled: boolean }> => {
  return API.put<{ enabled: boolean }>({
    path: "/organisations/" + organisationId.orgId + "/integrations/custom",
    data: data,
    token,
    organisationId,
  })
}

export default {
  get,
  update,
  getSqueezelyIntegration,
  getBloomReachIntegration,
  getGoogleTagManagerIntegration,
  getGoogleAnalyticsIntegration,
  getCustomIntegration,
  updateSqueezelyIntegration,
  updateBloomReachIntegration,
  updateGoogleTagManagerIntegration,
  updateGoogleAnalyticsIntegration,
  updateCustomIntegration,
}
