import React from "react"
import { useHistory } from "react-router-dom"

export type NavigationMap = typeof map
const map = {
  advisorOverview: () => `/advisors`,
  globalAnalytics: () => `/analytics`,
  advisorDashboard: (advisorId: string) => `/advisors/${advisorId}`,
  advisorFlow: (advisorId: string) => `/advisors/${advisorId}/flow`,
  advisorSettings: (advisorId: string) => `/advisors/${advisorId}/settings`,
  conversation: (advisorId: string) => `/advisors/${advisorId}/flow`,
  conversationAdviceCustomerInfo: (advisorId: string, screenId: string) =>
    `/advisors/${advisorId}/flow/screens/${screenId}/customer-contact`,
  advisorCreate: () => `/create-advisor`,
  screenInAdvisor: (advisorId: string, screenId: string) =>
    `/advisors/${advisorId}/flow/screens/${screenId}`,
  flowOfscreenInAdvisor: (advisorId: string, screenId: string) =>
    `/advisors/${advisorId}/flow/screens/${screenId}/rules`,
  questionInAdvisor: (advisorId: string, questionId: string) =>
    `/advisors/${advisorId}/flow/questions/${questionId}`,
  rulesOfQuestion: (advisorId: string, questionId: string) =>
    `/advisors/${advisorId}/flow/questions/${questionId}/rules`,
  mediaOfQuestion: (advisorId: string, questionId: string) =>
    `/advisors/${advisorId}/flow/questions/${questionId}/media`,
  ruleOfQuestion: (advisorId: string, questionId: string, ruleId: string) =>
    `/advisors/${advisorId}/flow/questions/${questionId}/rules?ruleId=${ruleId}`,
  matching: (advisorId: string) => `/advisors/${advisorId}/advice`,
  advice: (advisorId: string) => `/advisors/${advisorId}/advice`,
  adviceQuestion: (advisorId: string, questionId: string) =>
    `/advisors/${advisorId}/advice/${questionId}`,
  catalogueOverview: (advisorId: string) => `/advisors/${advisorId}/catalogue`,
  catalogue: (advisorId: string) => `/advisors/${advisorId}/catalogue`,
  catalogueCreate: (advisorId: string) =>
    `/advisors/${advisorId}/catalogue/create`,
  catalogueNewData: (advisorId: string) =>
    `/advisors/${advisorId}/catalogue/create/new`,
  catalogueUpdateData: (advisorId: string) =>
    `/advisors/${advisorId}/catalogue/update`,
  catalogueNewGoogleShoppingFeed: (advisorId: string) =>
    `/advisors/${advisorId}/catalogue/create/new-google-shopping-feed`,
  catalogueProductCatalogue: (advisorId: string) =>
    `/advisors/${advisorId}/catalogue`,
  catalogueUpdateHistory: (advisorId: string) =>
    `/advisors/${advisorId}/catalogue/update-history`,

  lookAndFeel: (advisorId: string) => `/advisors/${advisorId}/look-and-feel`,
  integration: (advisorId: string) => `/advisors/${advisorId}/integrate`,
  publishing: (advisorId: string) => `/advisors/${advisorId}/publish`,
  analyze: (advisorId: string) => `/advisors/${advisorId}/analyze`,

  adviceSettings: (advisorId: string) =>
    `/advisors/${advisorId}/advice?advice-settings=advice`,
}

const useNavigation = () => {
  const history = useHistory()

  const push = (fn) => history.push(fn)

  const navigation = React.useMemo(() => {
    return {
      toAdvisors: () => push(map.advisorOverview()),
      toAdvisor: (advisorId: string) => push(map.advisorDashboard(advisorId)),
      toAdvisorFlow: (advisorId: string) => push(map.advisorFlow(advisorId)),
      toAdvisorCreate: () => push(map.advisorCreate()),
      toScreenInAdvisor: (advisorId: string, screenId: string) =>
        push(map.screenInAdvisor(advisorId, screenId)),
      toFlowOfScreenInAdvisor: (advisorId: string, screenId: string) =>
        push(map.flowOfscreenInAdvisor(advisorId, screenId)),
      toQuestionInAdvisor: (advisorId: string, questionId: string) =>
        push(map.questionInAdvisor(advisorId, questionId)),
      toRulesOfQuestion: (advisorId: string, questionId: string) =>
        push(map.rulesOfQuestion(advisorId, questionId)),
      toRuleOfQuestion: (
        advisorId: string,
        questionId: string,
        ruleId: string
      ) => push(map.ruleOfQuestion(advisorId, questionId, ruleId)),
      toAdvice: (advisorId: string) => push(map.advice(advisorId)),
      toAdviceQuestion: (advisorId: string, questionId: string) =>
        push(map.adviceQuestion(advisorId, questionId)),
      toDataSources: (advisorId: string) =>
        push(map.catalogueOverview(advisorId)),
      toDataSource: (advisorId: string) => push(map.catalogue(advisorId)),
      toDataSourceNewData: (advisorId: string) =>
        push(map.catalogueNewData(advisorId)),
      toDataSourceUpdateData: (advisorId: string) =>
        push(map.catalogueUpdateData(advisorId)),
      toNewGoogleShoppingFeed: (advisorId: string) =>
        push(map.catalogueNewGoogleShoppingFeed(advisorId)),
      toProductCatalogue: (advisorId: string) =>
        push(map.catalogueProductCatalogue(advisorId)),
      toCatalogueCreate: (advisorId: string) =>
        push(map.catalogueCreate(advisorId)),
      toConversationCustomerInfo: (advisorId: string, screenId: string) =>
        push(map.conversationAdviceCustomerInfo(advisorId, screenId)),
      toAnalyze: (advisorId: string) => push(map.analyze(advisorId)),
      map,
      routes: map,
    }
  }, [history])

  return navigation
}

export default useNavigation
