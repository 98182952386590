import { useCallback, useState } from "react"
import { useAuth } from "~/context/auth"
import { useDispatch, useSelector } from "react-redux"
import {
  retrieveUserAccount,
  updateUserAccountInfo,
}from "~/actions/currentUser/actionCreators"
import { withRequestId } from "~/lib/requestId"
import { selectCurrentUser }from "~/store/currentUser/selectors"
import { Account }from "~/store/currentUser/types"
import { UUID }from "~/store/types"
import { requestStatus }from "~/store/requestStatus/selectors"
import { Status }from "~/store/requestStatus/reducer"
import { ApplicationState }from "~/store"
import { ApiError } from "~/api/APIBase"

export default () => {
  const { token, organisationId, logout } = useAuth()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const state = useSelector((state: ApplicationState) => state)

  const [req, setReq] = useState("")

  const useReqId = (): [
    string,
    React.Dispatch<React.SetStateAction<string>>
  ] => [req, setReq]

  const getRequestStatus = (requestId: UUID) => requestStatus(state, requestId)

  const isPending = (requestId: UUID): boolean => {
    const status = getRequestStatus(requestId)
    return status ? status.type === Status.PENDING : false
  }

  const fetchCurrentUser = useCallback(
    (onError?: (error: ApiError) => void) => {
      if (token && !currentUser)
        withRequestId((requestId) =>
          dispatch(
            retrieveUserAccount(requestId, token, organisationId, {
              logout,
              onError,
            })
          )
        )
    },
    [token, organisationId, currentUser, dispatch, logout]
  )

  const updateCurrentUserInfo = (
    info: Pick<
      Account,
      "firstName" | "familyName" | "profileImage" | "emailPreferences"
    >
  ) =>
    withRequestId((requestId) => {
      dispatch(
        updateUserAccountInfo(
          requestId,
          token!,
          organisationId,
          {
            ...currentUser!,
            profileImage: info.profileImage || currentUser!.profileImage,
            firstName: info.firstName || currentUser!.firstName,
            familyName: info.familyName || currentUser!.familyName,
            emailPreferences: {
              sendPeriodicUsageRecap:
                info.emailPreferences.sendPeriodicUsageRecap,
            },
          },
          { logout }
        )
      )
    })

  const img = currentUser?.profileImage?.urls["64x64.jpg"]
  const profileImage = img
    ? currentUser?.profileImage?.urls["64x64.jpg"] +
      `#${currentUser?.updatedAt}`
    : undefined

  return {
    currentUser,
    profileImage: profileImage,
    fetchCurrentUser,
    updateCurrentUserInfo,
    useReqId,
    isPending,
    getRequestStatus,
  }
}
