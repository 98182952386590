import React from "react"
import styled from "styled-components"

export default styled.div<{maxWidth?: boolean}>`
  margin: 0 0 2rem 0;
  max-width: ${p => p.maxWidth ? "1440px" : "auto"};
  padding: 3.75rem 2rem;
  @media screen and (min-width: 1280px) {
    padding: 3.75rem 3rem;
  }
  @media screen and (min-width: 1440px) {
    padding: 3.75rem 4rem;
  }
`

export const PageHeader: React.FC<{
  actions?: React.ReactNode
}> = ({ actions, children }) => {
  return (
    <div className="layout--page-header">
      <div>{children}</div>
      <div>{actions}</div>
    </div>
  )
}
