import { UUID }from "~/store/types"
import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import {
  startRequest,
  requestSuccess,
  requestFailure,
}from "~/actions/requestStatus/actions"
import { accounts } from "~/api"
import {
  fetchUserAccount,
  fetchUserAccountSuccess,
  fetchUserAccountFailure,
  changedUserAccount,
} from "./actions"
import { ApiError } from "~/api/APIBase"
import { Account, Role }from "~/store/currentUser/types"
import { OrganisationId } from "~/api/types"

export const retrieveUserAccount = (
  requestId: UUID,
  token: string,
  organisationId: OrganisationId,
  opts: { logout: () => void; onError?: (error: ApiError) => void }
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(fetchUserAccount(token))

    dispatch(startRequest(requestId))
    accounts
      .get(token, organisationId)
      .then((exAcc) => {
        dispatch(
          fetchUserAccountSuccess({
            id: exAcc.id,
            email: exAcc.email,
            firstName: exAcc.firstName,
            familyName: exAcc.familyName || "",
            profileImage: exAcc.profileImage,
            role: exAcc.role || Role.Admin,
            updatedAt: exAcc.updatedAt,
            featureFlags: exAcc.featureFlags,
            emailPreferences: exAcc.emailPreferences,
          })
        )
        dispatch(requestSuccess(requestId))
      })
      .catch((error: ApiError) => {
        dispatch(requestFailure(requestId, error))
        dispatch(fetchUserAccountFailure(error))
        if (error.status === 401 || error.status === 404) opts.logout()
        opts.onError && opts.onError(error)
        return error
      })
  }
}

export const updateUserAccountInfo = (
  requestId: UUID,
  token: string,
  organisationId: OrganisationId,
  account: Account,
  opts: { logout: () => void }
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(startRequest(requestId))

    accounts
      .update(token, organisationId, account)
      .then(() => {
        dispatch(changedUserAccount(account))
        dispatch(requestSuccess(requestId))
      })
      .catch((error: ApiError) => {
        dispatch(requestFailure(requestId, error))
        dispatch(fetchUserAccountFailure(error))
        if (error.status === 401) opts.logout()
        return error
      })
  }
}

export const updateUserPreferences = (
  requestId: UUID,
  token: string,
  organisationId: OrganisationId,
  account: Account,
  opts: { logout: () => void }
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(startRequest(requestId))

    accounts
      .update(token, organisationId, account)
      .then(() => {
        // dispatch(changedUserPreferences(account.preferences))
        dispatch(requestSuccess(requestId))
      })
      .catch((error: ApiError) => {
        dispatch(requestFailure(requestId, error))
        dispatch(fetchUserAccountFailure(error))
        if (error.status === 401) opts.logout()
        return error
      })
  }
}
