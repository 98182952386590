import React, { useState } from "react"
import Label from "~/components/forms/Label"
import PillButtons from "~/components/PillButtons"
import { Underline, Bold, Italic } from "~/components/icons/textstyles"
import styled, { css } from "styled-components"
import { HeaderStyle, StyleConfig } from "~/store/advisors"
import TabNav from "~/components/TabNav"
import LookAndFeelForm from "~/elm-tsx-wrappers/LookAndFeelForm"
import ColorsForm from "~/elm-tsx-wrappers/ColorsForm"
import FontList from "./FontList"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import FontSelect from "./FontSelect"

const Form = styled.div`
  position: relative;
  height: fit-content;
  min-height: calc(100vh - 7rem);
`

const FormSection = styled.div<{ active?: boolean }>`
  width: 100%;
  margin: 1.25rem 0 1rem;
  background: #fff;
  position: absolute;
  left: 0;
  z-index: -1;

  ${(p) =>
    p.active &&
    css`
      position: static;
    `};
`

type Props = {
  style: Required<StyleConfig>
  reset: number
  selectHeaderStyle: (value: HeaderStyle) => void
  changeStyleStringVal: (key: keyof StyleConfig, value: string) => void
  changeStyleBoolVal: (key: keyof StyleConfig, value: boolean) => void
  changeFont: (string: string) => void
  changeFontHeader: (string: string) => void
}

const StyleForm: React.FC<Props> = ({
  changeStyleStringVal,
  changeStyleBoolVal,
  selectHeaderStyle,
  changeFont,
  changeFontHeader,
  style,
  reset,
}) => {
  const [formSection, setFormSection] = useState<
    "colors" | "fonts" | "advanced"
  >("colors")
  const { featureFlags } = useFeatureFlags()

  return (
    <Form>
      <TabNav
        withUnderLineBorder={true}
        buttons
        options={[
          {
            label: "Colors",
            active: formSection === "colors",
            onClick: () => setFormSection("colors"),
          },
          {
            label: "Fonts",
            active: formSection === "fonts",
            onClick: () => setFormSection("fonts"),
          },
          {
            label: "Advanced",
            active: formSection === "advanced",
            onClick: () => setFormSection("advanced"),
          },
        ]}
      />
      <FormSection active={formSection === "colors"}>
        <ColorsForm style={style} changeStyleStringVal={changeStyleStringVal} />
        {featureFlags.showPoweredByAiden ? (
          <div className="hints mt-2">
            <p>
              Good to know: you can remove the 'made with Aiden' branding by
              switching to a Professional license. Contact{" "}
              <a
                href="mailto:support@aiden.cx"
                rel="noopener noreferrer"
                target="_blank"
              >
                support@aiden.cx
              </a>{" "}
              to discuss your options.
            </p>
          </div>
        ) : null}
      </FormSection>

      <FormSection active={formSection === "fonts"}>
        <div className="form-field">
          <Label>Font</Label>
          <FontSelect
            value={style.font}
            options={FontList}
            onSelect={(font) => {
              changeFont(font)
            }}
          />
        </div>

        <div className="form-field">
          <Label>Header Font</Label>
          <FontSelect
            value={style.fontHeader}
            options={FontList}
            onSelect={(font) => {
              changeFontHeader(font)
            }}
          />
        </div>

        <Label>Header Styling</Label>
        <PillButtons
          buttons={[
            {
              content: <Bold />,
              value: "bold",
              selected: style.header.includes("bold"),
              onClick: selectHeaderStyle,
            },
            {
              content: <Italic />,
              value: "italic",
              selected: style.header.includes("italic"),
              onClick: selectHeaderStyle,
            },
            {
              content: <Underline />,
              value: "underline",
              selected: style.header.includes("underline"),
              onClick: selectHeaderStyle,
            },
          ]}
        />
      </FormSection>

      <FormSection active={formSection === "advanced"}>
        <LookAndFeelForm
          style={style}
          changeStyleStringVal={changeStyleStringVal}
          changeStyleBoolVal={changeStyleBoolVal}
        />
      </FormSection>
    </Form>
  )
}
export default StyleForm
