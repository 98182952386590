import { changeCustomerInfoConfig } from "~/actions/advisors"
import Field from "~/components/forms/Field"
import FieldTextArea from "~/components/forms/FieldTextArea"
import HelpText from "~/components/HelpText"
import MarkdownTextarea from "~/components/MarkdownTextarea"
import SubHeading from "~/components/SubHeading"
import ToggleSwitch from "~/components/ToggleSwitch"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AdviceCustomerInfoConfig, Advisor } from "~/store/advisors"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import NoResultsCustomerInfoEditor from "./NoresultsCustomerInfoEditor"
import { AdviceScreen } from "~/store/screens"
import { getScreens } from "~/store/screens/selectors"
import { ApplicationState } from "~/store"
import TutorialLink from "~/components/TutorialLink"

const CustomerInfoEditor: React.FC<{ advisor: Advisor }> = ({ advisor }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { featureFlags } = useFeatureFlags()

  const customerInfo: AdviceCustomerInfoConfig = advisor.customerInfo

  const updateCustomerInfo = React.useCallback(
    (info: AdviceCustomerInfoConfig) => {
      featureFlags.customerInfoCollectionEnabled &&
        dispatch(changeCustomerInfoConfig(advisor.id, info))
    },
    [advisor.id, dispatch]
  )

  const adviceScreen: AdviceScreen | undefined = useSelector(
    (state: ApplicationState) => getScreens(state, advisor.id)?.advice
  )

  return (
    <>
      {!featureFlags.customerInfoCollectionEnabled ? (
        <div className="hints mb-2">
          <p>
            This feature is available on the Professional license or higher. You can read more about it in the tutorial.
          </p>
          <TutorialLink
            article="email-results"
            label="Send the advice by e-mail"
          />
        </div>
      ) : null}
      <h2>With results</h2>
      {/* <HelpText>{t("screenEditor.customerInfo.withResultsHelp")}</HelpText> */}

      <div className="mb-2">
        <p className="subtle-text ">
          Ask for the customer's email address when the advice has results.{" "}
        </p>
        <TutorialLink
          article="email-results"
          label="Send the advice by e-mail"
        />
      </div>
      <ToggleSwitch
        toggledOn={
          customerInfo.showForm && featureFlags.customerInfoCollectionEnabled
        }
        label={t("screenEditor.customerInfo.showForm")}
        onChange={(show) =>
          updateCustomerInfo({
            ...customerInfo,
            showForm: show,
          })
        }
      />
      <HelpText>{t("screenEditor.customerInfo.showFormHelp")}</HelpText>
      {customerInfo.showForm && (
        <div className="hints">
          <p>
            {t("screenEditor.customerInfo.hintStart")}
          </p>
        </div>
      )}
      <FieldTextArea
        disabled={!customerInfo.showForm}
        label={t("screenEditor.customerInfo.ctaButtonLabel")}
        value={customerInfo.ctaButtonLabel}
        maxLength={70}
        hasError={
          customerInfo.showForm && customerInfo.ctaButtonLabel.length > 70
        }
        // hasError={
        //   customerInfo.showForm && customerInfo.ctaButtonLabel.length === 0
        // }
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            ctaButtonLabel: value,
          })
        }
      />
      <HelpText>{t("screenEditor.customerInfo.ctaButtonLabelHelp")}</HelpText>
      <SubHeading>Customer contact form</SubHeading>
      <FieldTextArea
        disabled={!customerInfo.showForm}
        label={t("screenEditor.customerInfo.form.title")}
        // hasError={customerInfo.showForm && customerInfo.title.length === 0}
        value={customerInfo.title}
        maxLength={700}
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            title: value,
          })
        }
      />
      <MarkdownTextarea
        disabled={!customerInfo.showForm}
        label={t("screenEditor.customerInfo.form.intro")}
        // hasError={customerInfo.showForm && customerInfo.intro.length === 0}
        text={customerInfo.intro}
        maxLength={700}
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            intro: value,
          })
        }
      />
      <HelpText>{t("screenEditor.customerInfo.form.introHelp")}</HelpText>
      <Field
        label={t("screenEditor.customerInfo.checkboxLabel")}
        value={customerInfo.checkboxLabel}
        maxLength={64}
        markdownOpts={["link"]}
        // hasError={
        //   customerInfo.showForm &&
        //   customerInfo.showCheckbox &&
        //   customerInfo.checkboxLabel.length === 0
        // }
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            checkboxLabel: value,
          })
        }
        disabled={!customerInfo.showForm || !customerInfo.showCheckbox}
        labelExtra={
          <ToggleSwitch
            toggledOn={customerInfo.showForm && customerInfo.showCheckbox}
            label={t("screenEditor.customerInfo.showCheckbox")}
            flipLabel
            onChange={(show) =>
              updateCustomerInfo({
                ...customerInfo,
                showCheckbox: show,
              })
            }
          />
        }
      />
      <HelpText>{t("screenEditor.customerInfo.checkboxHelp")}</HelpText>
      <MarkdownTextarea
        disabled={!customerInfo.showForm}
        label={t("screenEditor.customerInfo.formSubmittedMessage")}
        text={customerInfo.formSubmittedMessage}
        // hasError={
        //   customerInfo.showForm &&
        //   customerInfo.formSubmittedMessage.length === 0
        // }
        maxLength={700}
        onChange={(value) =>
          updateCustomerInfo({
            ...customerInfo,
            formSubmittedMessage: value,
          })
        }
        bounds={"#sidebar"}
        dataTestId={"advice-customer-info-received-note"}
      />
      <HelpText>
        {t("screenEditor.customerInfo.formSubmittedMessageHelp")}
      </HelpText>
      <hr />
      {adviceScreen ? (
        <>
          <h2 style={{ marginTop: "4rem" }}>Empty advice</h2>
          <p className="subtle-text mb-2">
            Ask for the customer's email adress when the advice has no results.{" "}
          </p>

          <SubHeading>Customer contact form</SubHeading>

          <NoResultsCustomerInfoEditor
            advisor={advisor}
            screen={adviceScreen}
          />
        </>
      ) : null}
    </>
  )
}

export default CustomerInfoEditor
