import React from "react"
import "./App.scss"
import "emoji-mart/css/emoji-mart.css"
import { configureStore } from "~/store"
import styled from "styled-components"
import AuthenticatedApp from "~/containers/AuthenticatedApp"
import UnauthenticatedApp from "~/containers/UnauthenticatedApp"
import { useAuth } from "~/context/auth"
import { ThemeContextProvider } from "~/context/theme"
import { RedirectToContextProvider } from "~/context/redirectTo"
import { AuthProvider } from "~/context/auth"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Invitation from "~/components/Invitation"
import { Provider } from "react-redux"
import { setup } from "~/Sentry"
import { useTranslation } from "react-i18next"
import { Language } from "~/i18n"
import useMountEffect from "~/hooks/useMountEffect"
import { IntercomProvider } from "react-use-intercom"
import { EnvVar, envVar } from "~/utils/environment"
import { Integrations } from "@sentry/tracing"

const store = configureStore()
setup()

const AppWrapper = styled.div`
  transition: background 200ms ease-in, color 200ms ease-in;
  background: ${(props) => props.theme.backgroundShade};
  color: ${(props) => props.theme.main};
  /* font-size: 16px; */
  min-height: 100vh;
  padding: 0;

  .link,
  .link:visited {
    color: ${(p) => p.theme.secondary};
  }
  .link:hover {
    text-decoration: underline;
  }
`

declare global {
  interface Window {
    _env_: any
  }
}

function Core() {
  const { isAuthenticated } = useAuth()

  return (
    <AppWrapper>
      <Router>
        <Switch>
          <Route path="/invitation" render={() => <Invitation />} />
          <Route
            path="*"
            render={() =>
              isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />
            }
          />
        </Switch>
      </Router>
    </AppWrapper>
  )
}

function App() {
  const { i18n } = useTranslation()
  const requestedLocation = window.location.pathname + window.location.search

  useMountEffect(() => {
    i18n.changeLanguage(Language.americanEnglish)
  })

  const intercomOptions = {
    enabled: envVar(EnvVar.INTERCOM_ENABLED) === true,
    appId: envVar(EnvVar.INTERCOM_APP_ID)?.toString() || "",
    apiBase: envVar(EnvVar.INTERCOM_API_BASE)?.toString() || "",
    intercomBootProps: {
      hideDefaultLauncher: false,
    },
  }

  return (
    <Provider store={store}>
      <IntercomProvider
        shouldInitialize={intercomOptions.enabled}
        appId={intercomOptions.appId}
        apiBase={intercomOptions.apiBase}
        autoBootProps={intercomOptions.intercomBootProps}
      >
        <AuthProvider>
          <ThemeContextProvider>
            <RedirectToContextProvider requestedLocation={requestedLocation}>
              <Core />
            </RedirectToContextProvider>
          </ThemeContextProvider>
        </AuthProvider>
      </IntercomProvider>
    </Provider>
  )
}

export default App
