import Quill from "quill"
import TurndownService from "turndown"
import { marked } from "marked"
import DOMPurify from "dompurify"

const turndownService = new TurndownService()

class QuillEditor extends HTMLElement {
  static get observedAttributes() {
    // Which attributes call attributeChangedCallback
    return ["value"]
  }

  connectedCallback() {
    const editor = this

    if (editor.isConnected) {
      const value: string = editor.getAttribute("content") || ""
      const bounds: string = editor.getAttribute("bounds") || "#sidebar"
      const toolbarOpts: string[] = (
        editor.getAttribute("toolbar") || ""
      ).split(",")
      const toolbar = toolbarOpts.includes("list")
        ? [
            toolbarOpts.filter((a) => a !== "list"),
            [{ list: "ordered" }, { list: "bullet" }],
          ]
        : toolbarOpts
      const maxLength: number = parseInt(editor.getAttribute("maxlength") || "")
      const hasMaxLength = isFinite(maxLength) && !isNaN(maxLength)

      editor.innerHTML = DOMPurify.sanitize(marked.parse(value))

      var quill = new Quill(editor, {
        modules: {
          toolbar: toolbar,
        },
        theme: "snow",
        bounds: bounds,
      })

      var Link = Quill.import("formats/link")
      Link.sanitize = function (url) {
        if (!url.match(/^([a-zA-Z]+:)?\/\//)) return "https://" + url
        return url
      }

      quill.on("text-change", function (_delta, _oldDelta, _source) {
        if (hasMaxLength && quill.getLength() > maxLength) {
          quill.deleteText(maxLength, quill.getLength())
        }

        editor.onInput(turndownService.turndown(quill.root.innerHTML))
      })
    }
  }

  // Same as <input>'s oninput
  onInput(value: string) {
    const event = new CustomEvent("input", { detail: value })
    this.dispatchEvent(event)
  }

  // Update content if changed
  attributeChangedCallback(_name, _oldValue, newValue) {
    this.innerHTML = newValue || ""
  }
}

const define = () => customElements.define("quill-editor", QuillEditor)

export { define }
