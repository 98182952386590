import React, { useEffect, useState } from "react"
import { StyleConfig } from "~/store/advisors"
import PillButtons from "~/components/PillButtons"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import Mobile from "~/components/icons/Mobile"
import Desktop from "~/components/icons/Desktop"

import productImage from "~/images/generic-product.jpg"
import i18n from "~/i18n"
import LoadingSpinner from "~/components/Loading"
import useMountEffect from "~/hooks/useMountEffect"
import { envVar, EnvVar } from "~/utils/environment"
import useNotifications from "~/hooks/useNotifications"
import Warning from "~/components/icons/Warning"
import HelpText from "~/components/HelpText"
import Appear from "~/animations/Appear"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import { PreviewMode } from "~/types"
import { FullPage, InPage } from "~/components/icons/PreviewMode"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Options = styled.div`
  margin: 1rem 0 2rem;
  display: flex;
  flex-wrap: wrap;
  div + div {
    margin-left: 1rem;
  }
`

const PreviewContainer = styled.div<{
  mode: string
  background: string
  bannerMode: boolean
}>`
  margin: 0;
  display: flex;
  max-width: 100%;
  animation: ${Appear.fadeIn} 0.2s 0.25s ease-in forwards;
  opacity: 0;

  ${(p) => {
    if (!p.bannerMode) {
      return css`
        box-shadow: 0 0 0 1px ${(p) => p.theme.shade2};
      `
    }
  }}

  ${(p) => {
    if (p.mode === "desktop") {
      return css`
        width: 900px;
      `
    } else {
      return css`
        width: 375px;

        #preview {
          .product-card.product-results__result {
            width: 95% !important;
          }
        }
      `
    }
  }}
  #preview {
    width: 100%;

    .aiden-webshop-app {
      padding: 0 !important;

      .wrapper {
        box-shadow: none !important;
      }
    }
  }

  main {
    min-height: 100%;
  }
`

const introScreen = {
  id: "string",
  title: null,
  component: "intro",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "Header",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "p",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "info-p",
      id: "intro-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "button",
      id: "button",
      label: "Start",
      payload: {},
      properties: {},
      complete: false,
    },
  ],
}

const infoScreen = {
  id: "string",
  title: null,
  component: "info",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "Header",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "p",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "info-p",
      id: "intro-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {},
      complete: false,
    },
  ],
}

const questionScreen = {
  id: "string",
  title: null,
  component: "question",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "Question",
      payload: {},
      properties: {},
      complete: true,
    },
    {
      component: "p",
      id: "p",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: true,
    },
    {
      component: "checkbox",
      id: "checkbox",
      label: "Start",
      payload: {},
      properties: {
        "a1.help":
          "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      },
      attributeInstanceId: "a1@entity$0",
      value: "a1",
      options: [
        {
          label: "First answer",
          value: "a1",
        },
        {
          label: "Second answer",
          value: "a2",
        },
      ],
      complete: true,
    },
  ],
}
const adviceScreen = {
  id: "string",
  title: null,
  component: "advice",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "The advice",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "h2",
      id: "h2",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "advice-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {
        className: "info",
      },
      complete: false,
    },
    {
      component: "info-p",
      id: "advice-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "results",
      id: "results",
      label: null,
      payload: {},
      properties: {},
      complete: false,
      controls: Array.from(Array(3).keys()).map((i) => ({
        component: "product-card",
        id: "product-card" + i,
        label: null,
        payload: {
          productId: "productId",
          productUrl: "#",
          imageUrl: productImage,
          productName: "Product Name",
          price: "€24.99",
          salePrice: "€19.99",
          salePriceStart: undefined,
          salePriceEnd: undefined,
          features: [
            {
              score: "perfect",
              answer: "Perfect match",
            },
          ],
          cta: "View",
          fields: [
            {
              label: "Product description",
              suffix: "",
              values: ["Fusce dapibus, tellus ac cursus ut fermentum massa."],
            },
          ],
        },
        properties: {},
        complete: false,
        controls: [],
      })),
    },
  ],
}

const dataBuilder = (screen, style, previewMode) => {
  return {
    context: {},
    currentScreenId: "string",
    entities: [],
    history: [],
    nextScreenId: null,
    prevScreenId: null,
    screen: screen,
    screenReportGroups: [],
    sessionId: "string",
    styleConfig: style,
    previewMode: previewMode,
  }
}

type PreviewProps = {
  advisorId: string
  style: StyleConfig
  introScreenDisabled: boolean
}

const loadCss = (url: string, id: string, location: HTMLHeadElement) =>
  new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      var link = document.createElement("link")
      link.id = id
      link.rel = "stylesheet"
      link.type = "text/css"
      link.href = url
      link.media = "all"
      link.onload = resolve
      link.onerror = reject
      location.appendChild(link)
    }
  })

const loadJs = (url: string, id: string, location: HTMLHeadElement) =>
  new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      var scriptTag = document.createElement("script")
      scriptTag.src = url
      scriptTag.id = id
      if (url.includes(".ts")) {
        scriptTag.type = "module"
      }

      scriptTag.onload = resolve
      scriptTag.onerror = reject

      location.appendChild(scriptTag)
    }
  })

const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL) as string
const urlFor = (file: string, devFile: string) =>
  webshopPreviewURL.includes("localhost:5000")
    ? `${webshopPreviewURL}${devFile}`
    : `${webshopPreviewURL}${file}`

const AidenPreview: React.FC<PreviewProps> = ({
  advisorId,
  style,
  introScreenDisabled,
}) => {
  const [preview, setPreview] = useState<any>()
  const [screen, setScreen] = useState<
    "intro" | "question" | "advice" | "info"
  >("intro")
  const [mode, setMode] = useState<"desktop" | "mobile">("desktop")
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { notify } = useNotifications()
  const [previewMode, setPreviewMode] = React.useState<PreviewMode>("inpage")
  const { featureFlags } = useFeatureFlags()

  const screenProperties = React.useMemo(() => {
    return featureFlags.showPoweredByAiden ? { showPoweredByAiden: "true" } : {}
  }, [featureFlags.showPoweredByAiden])

  useMountEffect(() => {
    // @ts-ignore
    if (window.preview) {
      setLoading(false)
    } else {
      const head = document.getElementsByTagName("head")[0]

      Promise.all([
        // loadCss(urlFor("/global.css"), "globalCSSId", head),
        loadCss(
          urlFor("/build/preview.css", "/src/global.css"),
          "bundleCSSId",
          head
        ),
        loadJs(
          urlFor("/build/preview.js", "/src/preview.ts"),
          "aiden-preview",
          head
        ),
      ])
        .catch(() => {
          setError(true)
          notify({ type: "error", text: t("errors.error") })
        })
        .finally(() => setLoading(false))
    }
  })

  useEffect(() => {
    // @ts-ignore
    const Preview = window.AidenPreview

    if (!loading && Preview) {
      try {
        const prev = new Preview({
          target: document.querySelector("#preview"),
          props: {
            data: dataBuilder(introScreen, style, previewMode),
            locale: i18n.language,
            getAttribute: () => "a1",
          },
        })

        setPreview(prev)
      } catch (error) {
        setError(true)
        notify({ type: "error", text: t("errors.error") })
        console.error(error)
      }
    }
    // eslint-disable-next-line
  }, [loading, previewMode])

  useEffect(() => {
    let scr
    switch (screen) {
      case "intro":
        scr = { ...introScreen, properties: screenProperties }
        break
      case "question":
        scr = { ...questionScreen, properties: screenProperties }
        break
      case "info":
        scr = { ...infoScreen, properties: screenProperties }
        break
      case "advice":
        scr = { ...adviceScreen, properties: screenProperties }
        break
    }

    preview && preview.$set({ data: dataBuilder(scr, style, previewMode) })
  }, [style, preview, screen, previewMode, screenProperties])

  if (loading) return <LoadingSpinner />
  if (error) return null

  return (
    <Wrapper>
      <Options>
        <PillButtons
          buttons={[
            {
              content: t("lookAndFeel.screens.intro"),
              value: "intro",
              selected: screen === "intro",
              onClick: setScreen,
              hasTextContent: true,
            },
            {
              content: t("lookAndFeel.screens.question"),
              value: "question",
              selected: screen === "question",
              onClick: setScreen,
              hasTextContent: true,
            },
            {
              content: t("lookAndFeel.screens.info"),
              value: "info",
              selected: screen === "info",
              onClick: setScreen,
              hasTextContent: true,
            },
            {
              content: t("lookAndFeel.screens.advice"),
              value: "advice",
              selected: screen === "advice",
              onClick: setScreen,
              hasTextContent: true,
            },
          ]}
        />
        <PillButtons
          buttons={[
            {
              content: <Desktop />,
              value: "desktop",
              selected: mode === "desktop",
              onClick: setMode,
            },
            {
              content: <Mobile />,
              value: "mobile",
              selected: mode === "mobile",
              onClick: setMode,
            },
          ]}
        />
        <PillButtons
          buttons={[
            {
              content: (
                <div>
                  <FullPage />
                </div>
              ),
              value: "inpage",
              selected: previewMode === "inpage",
              onClick: () => setPreviewMode("inpage"),
              hasTextContent: false,
            },

            {
              content: (
                <div>
                  <InPage />
                </div>
              ),
              value: "banner",
              selected: previewMode === "banner",
              onClick: () => setPreviewMode("banner"),
              hasTextContent: false,
            },
          ]}
        />
      </Options>
      {introScreenDisabled && (
        <HelpText>
          <Warning />
          {t("lookAndFeel.feedback.disabledIntroScreen")}
        </HelpText>
      )}
      <PreviewContainer
        mode={mode}
        background={style.background}
        bannerMode={previewMode === "banner"}
        key={previewMode}
      >
        <div id="preview"></div>
      </PreviewContainer>
    </Wrapper>
  )
}

export default AidenPreview
