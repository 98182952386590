import React, { useEffect, useCallback, useState } from "react"
import { advisors } from "~/api"
import { EnvVar, envVar } from "~/utils/environment"
import { useAuth } from "~/context/auth"
import LoadingSpinner from "~/components/LoadingSpinner"
import Eye from "~/components/icons/Eye"
import { Cross } from "~/components/icons"
import Appear from "~/animations/Appear"
import styled, { css } from "styled-components"
import { createPortal } from "react-dom"
import { CopyPreviewUrl } from "./CopyPreviewUrl"
import { usePreviewMode } from "~/context/previewMode"
import SelectPreviewMode from "~/components/SelectPreviewMode"
import { useDispatch, useSelector } from "react-redux"
import { notify } from "~/actions/notifications"
import { useProductOptions } from "./useProductOptions"
import { ApplicationState } from "~/store"
import { getScreens } from "~/store/screens/selectors"

const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  animation-name: ${Appear.fadeIn};
  animation-duration: 250ms;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  box-sizing: border-box;
  scroll-behavior: smooth;
`

const ModalInner = styled.div`
  /* padding: 9.75rem 1rem 5rem; */
  width: 100%;
  & iframe {
    height: 70vh;
  }
`

const FrameWrapper = styled.div<{ bannerMode: boolean }>`
  /* max-width: 1000px; */\
  max-width: 94vw;
  margin: 0 auto;
  animation: ${Appear.fadeIn} 0.2s 0.25s ease-in forwards;
  opacity: 0;
  background: #ffffff;

  @media screen and (min-width: 84rem){
    max-width: 80rem;
  }

  ${(p) =>
    p.bannerMode &&
    css`
      padding: 2rem 2rem;
      background: #ffffff;
      box-shadow: ${(p) => p.theme.modalShadow};
    `}
`

const Controls = styled.div`
  position: relative;
  /* width: calc(100% - 2rem);
  max-width: 1000px;
  margin: 0 auto;
  position: sticky;
  top: 3.625rem;
  z-index: 1; */

  padding: 1rem;
  margin: 2rem auto 0 auto;
  /* margin-bottom: 4rem; */
  /* background: ${(p) => p.theme.main}; */
  max-width: 94vw;

  background: #f6fafd;
  background: #cdd8e1;
  border-top-left-radius: ${(p) => p.theme.borderRadius};
  border-top-right-radius: ${(p) => p.theme.borderRadius};
  border-bottom: 2px solid $shade4;

  select {
    background: #e4ebef;
    border: 1px solid ${(p) => p.theme.shade3};
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  @media screen and (min-width: 84rem) {
    max-width: 80rem;
  }
`

const CloseModal = styled.button`
  position: absolute;
  background: white;
  top: -1rem;
  right: -1rem;
  box-shadow: ${(p) => p.theme.modalShadow};
`

const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL)
const webshopPreviewUrlPath =
  webshopPreviewURL === "http://localhost:5000" ? "/development.html" : ""

export const Preview: React.FC<{ advisorId: string; label: string }> = ({
  advisorId,
  label,
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const [previewLoading, setPreviewLoading] = React.useState(false)
  const clickedOutside = React.useRef(false)
  const { token, organisationId } = useAuth()
  const dispatch = useDispatch()
  const previewMode = usePreviewMode().getPreviewMode(advisorId)
  const closeModal = () => setShowModal(false)
  const {
    refresh: refreshProducts,
    loading: productsLoading,
    products,
  } = useProductOptions(advisorId)

  const pdpEnabled = useSelector((store: ApplicationState) => {
    const screens = getScreens(store, advisorId)
    if (screens) {
      return screens.advice.productScopeConfig.enabled
    }
    return false
  })
  const [pdpProductId, setPdpProductId] = useState<undefined | string>(
    undefined
  )

  useEffect(() => {
    const onClickOutside = (e: any) => {
      if (e.target.id === "open-preview") return
      clickedOutside.current = true
      setPreviewLoading(false)
    }
    if (previewLoading) window.addEventListener("click", onClickOutside)
    return () => window.removeEventListener("click", onClickOutside)
  }, [clickedOutside, previewLoading, setPreviewLoading])

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      setPreviewLoading(true)
      advisors
        .preview(advisorId, token!, organisationId)
        .then(() => {
          if (!clickedOutside.current) {
            refreshProducts()
            setShowModal(true)
          }
        })
        .catch(() => {
          dispatch(notify({ text: "Could not create preview", type: "error" }))
        })
        .finally(() => {
          setPreviewLoading(false)
          clickedOutside.current = false
        })
    },
    [advisorId, dispatch, token, organisationId]
  )

  const handleFrameEvent = useCallback((event: any) => {
    if (event.data.advisorId) {
      const frame: HTMLElement | null = document.querySelector(
        "iframe#aiden-" + event.data.advisorId
      )
      const modal: HTMLElement | null = document.querySelector(
        "div#aiden-modal-" + event.data.advisorId
      )

      if (frame) {
        if (event.data.type === "viewport-dimensions") {
          // The 1 pixel is neccesary to fix issues on iframes on mobile android
          frame.style.height = event.data.data.height + 1 + "px"
        }
      }

      if (modal) {
        if (event.data.type === "screen-change") {
          modal.scrollTop = 0
        }
      }
    }
  }, [])

  useEffect(() => {
    if (showModal) window.addEventListener("message", handleFrameEvent)
    return () => window.removeEventListener("message", handleFrameEvent)
  }, [handleFrameEvent, showModal])

  useEffect(() => {
    const closeOnClickOutside = (e: any) => {
      const modal = document.getElementById("aiden-modal-inner-" + advisorId)
      if (!modal?.contains(e.target)) {
        setShowModal(false)
      }
    }
    if (showModal) window.addEventListener("click", closeOnClickOutside)
    return () => window.removeEventListener("click", closeOnClickOutside)
  }, [showModal, setShowModal, advisorId])

  useEffect(() => {
    const closeOnEscape = (e: any) => {
      if (e.keyCode === 27) {
        // Escape keyCode
        setShowModal(false)
      }
    }
    if (showModal) window.addEventListener("keyup", closeOnEscape)
    return () => window.removeEventListener("keyup", closeOnEscape)
  }, [showModal, setShowModal])

  useEffect(() => {
    const frame = document.getElementById("aiden-" + advisorId)
    const previewButton = document.getElementById("open-preview")
    const handleTouchMove = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
    }
    if (showModal) {
      document.body.style.height = "100%"
      document.body.style.overflow = "hidden"
      frame && frame.focus()
      window.addEventListener("touchmove", handleTouchMove, { passive: false })
    }
    return () => {
      document.body.style.height = "auto"
      document.body.style.overflow = "visible"
      previewButton && previewButton.focus()
      window.removeEventListener("touchmove", handleTouchMove)
    }
  }, [advisorId, showModal])

  return (
    <>
      <button
        id="open-preview"
        className="menu-link as-button btn"
        onClick={
          previewLoading
            ? () => {}
            : (e: any) => {
                handleClick(e)
              }
        }
      >
        <span
          style={{
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="icon">
            {previewLoading ? <LoadingSpinner size="small" /> : <Eye />}
          </span>
          <span className="label">{label && <span>{label}</span>}</span>
        </span>
      </button>
      {showModal &&
        createPortal(
          <Modal id={`aiden-modal-` + advisorId}>
            <div
              style={{ maxWidth: "80rem", margin: "3rem auto 0 auto" }}
              id={`aiden-modal-inner-` + advisorId}
            >
              <Controls>
                <div className="horizontal-group has-start-items has-justified-between-items">
                  <div>
                    <label>View:</label>
                    <SelectPreviewMode advisorId={advisorId} />
                  </div>
                  {pdpEnabled ? (
                    <div>
                      <label>Preview product check:</label>

                      <select
                        onChange={(event) =>
                          setPdpProductId(event.target.value)
                        }
                        style={{ width: "20rem", display: "block" }}
                        value={pdpProductId || ""}
                      >
                        {productsLoading ? (
                          <></>
                        ) : (
                          <>
                            <option value="">Select a product...</option>,
                            {products.map((p) => {
                              return (
                                <option key={p.id} value={p.productId}>
                                  {p.name}
                                </option>
                              )
                            })}
                          </>
                        )}
                      </select>
                    </div>
                  ) : (
                    <div />
                  )}

                  <CloseModal
                    className="btn secondary btn-small btn-circle"
                    onClick={closeModal}
                  >
                    <Cross />
                  </CloseModal>
                  <div style={{ marginTop: "1.5rem" }}>
                    <CopyPreviewUrl advisorId={advisorId} />
                  </div>
                </div>
              </Controls>
              <ModalInner>
                <FrameWrapper
                  bannerMode={previewMode === "banner"}
                  key={previewMode}
                >
                  <iframe
                    title="advisor preview"
                    id={`aiden-` + advisorId}
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                    src={`${webshopPreviewURL}${webshopPreviewUrlPath}/?advisorId=${advisorId}&preview=true&layout=vertical&mode=${previewMode}${
                      pdpProductId ? `&productId=${pdpProductId}` : ""
                    }`}
                  />
                </FrameWrapper>
              </ModalInner>
            </div>
          </Modal>,
          document.getElementById("modal")!
        )}
    </>
  )
}
