import ChevronCollapse from "~/components/icons/ChevronCollapse"
import ChevronExpand from "~/components/icons/ChevronExpand"
import DT from "~/lib/DT"
import React from "react"
import styled from "styled-components"

const Item = styled.div<{ status: string }>`
  background: ${(p) => (p.status === "ok" ? p.theme.shade2 : "#FCF9EC")};
  border-radius: 0.25rem;
  margin: 1rem 0;

  .issues-list--item{
    margin-bottom: .5rem;
  }

  .item__toggle {
    min-width: 3rem;
    display: flex;
    justify-content: center;
    pointer-events: none;
    &.toggleable {
      cursor: pointer;
      pointer-events: all;
    }
  }
`
const ItemHeader = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`
const ItemBody = styled.div`
  padding: 0 1rem 1rem 1rem;
`

const UpdateListItem: React.FC<{
  icon: React.ReactNode
  label: React.ReactNode
  status?: "ok" | "warning"
  timeStamp: number
  isOpen: boolean
}> = (props) => {
  const [open, setOpen] = React.useState(props.isOpen)
  const toggle = () => setOpen(!open)
  return (
    <Item status={props.status || "ok"}>
      <ItemHeader onClick={toggle}>
        <span>{props.icon}</span>
        <p style={{ margin: 0 }}>{props.label}</p>
        <span style={{ whiteSpace: "nowrap" }}>
          {DT.format(props.timeStamp)}
        </span>
        <span className={`item__toggle ${props.children ? "toggleable" : ""}`}>
          {!!props.children && (
            <>{open ? <ChevronCollapse /> : <ChevronExpand />}</>
          )}
        </span>
      </ItemHeader>
      {props.children && open && <ItemBody>{props.children}</ItemBody>}
    </Item>
  )
}

export default UpdateListItem
