import { useHistory } from "react-router"
import Elm from "./ElmWrapper"
import { DataSource } from "~/types"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { questionLabelColors } from "~/themes"

export const Catalogue: React.FC<{
  advisorId: string
  dataSource: DataSource
}> = (props) => {
  const history = useHistory()

  const [ports, setPorts] = React.useState<any>()
  const { token: authToken } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string

  const { productLabel } = useAdvisorContext()

  function setupPorts(ports) {
    setPorts(ports)
    const pushUrlHandler = function (url) {
      history.push(url)
      ports.onUrlChange.send(window.location.href)
    }

    ports.gotoUrl.subscribe(pushUrlHandler)

    const urlChangeHandler = function () {
      ports.onUrlChange.send(window.location.href)
    }
    // Inform app of browser navigation (the BACK and FORWARD buttons)
    window.addEventListener("popstate", urlChangeHandler)

    return () => {
      ports.gotoUrl.unsubscribe(pushUrlHandler)
      window.removeEventListener("popstate", urlChangeHandler)
    }
  }

  React.useEffect(() => {
    return () => {
      if (ports) ports.stop.send(true)
    }
  }, [ports])

  return (
    // @ts-ignore
    <Elm
      src={ElmModule.Catalogue}
      flags={{
        advisorId: props.advisorId,
        dataSource: props.dataSource,
        authToken: authToken,
        apiUrl: apiUrl,
        advisorTitle: "advisorTitle",
        catalogueTitle: productLabel,
        productLabel: productLabel,
        url: window.location.href,
        questionLabelColors: questionLabelColors,
      }}
      ports={setupPorts}
    />
  )
}
