import React, { useState } from "react"
import { useImageSelectPreview } from "~/hooks/useImageSelectPreview"
import AccountInfo from "./AccountInfo"
import { Account }from "~/store/currentUser/types"
import UserInfo from "./UserInfo"
import ArrowRight from "~/components/icons/ArrowRight"
import Button from "~/components/Button"
import ButtonBar from "~/components/ButtonBar"
import ThumbsUp from "~/components/icons/ThumbsUp"
import { useTranslation } from "react-i18next"
import { animated, useTransition } from "react-spring"
import useMeasure from "react-use-measure"
import { accounts } from "~/api"
import { useHistory } from "react-router"
import useNavigation from "~/hooks/useNavigation"
import { useAuth } from "~/context/auth"
import HelpText from "~/components/HelpText"
import LoadingSpinner from "~/components/Loading"
import JWT from "~/lib/JWT"

const defaultCrop = { width: 0, height: 0, x: 0, y: 0 }
const InvitationForm: React.FC<{ inviteToken: string; email: string }> = ({
  inviteToken,
  email,
}) => {
  const { t } = useTranslation()
  const { login, isAuthenticated, token, organisationId } = useAuth()
  const { routes } = useNavigation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [firstName, setFirstName] = useState("")
  const [familyName, setFamilyName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [validPassword, setValidPassword] = useState(false)
  const [isCropping, setIsCropping] = useState(false)
  const [crop, setCrop] = useState(defaultCrop)

  const { selectedImage, previewUrl, onSelectImage } = useImageSelectPreview()

  const transitions = useTransition(isAuthenticated, null, {
    initial: {
      opacity: 1,
      transform: `translateX(0)`,
    },
    from: {
      opacity: 0,
      transform: `translateX(${isAuthenticated ? "50%" : "-50%"})`,
    },
    enter: { opacity: 1, transform: `translateX(0)` },
    leave: {
      opacity: 0,
      transform: `translateX(${isAuthenticated ? "-50%" : "50%"})`,
    },
  })

  // Gets the height of the element (ref)
  const [ref, { height }] = useMeasure()

  const accountInfoValid = validPassword && email.length >= 3
  const userInfoValid =
    firstName.length >= 1 && familyName.length >= 1 && !isCropping

  const createAccount = async () => {
    try {
      setLoading(true)
      const account: Account = await accounts.newAccountFromInvite(
        inviteToken,
        {
          password,
          passwordConfirm: passwordConfirmation,
          firstName,
          familyName,
          profileImage: selectedImage,
          emailPreferences: { sendPeriodicUsageRecap: true },
        }
      )

      await login(account.email, password)
      setLoading(false)
    } catch (e: any) {
      switch (e?.data?.status) {
        case "already_exists":
          setError(t("invitation.error.already_exists"))
          break
        case "not_found":
        case "invalid":
          setError(t("invitation.error.not_found"))
          break
        default:
          setError(t("errors.error"))
          break
      }

      setLoading(false)
    }
  }

  const updateUserInfo = async () => {
    if (token) {
      setLoading(true)
      const validCrop = Object.values(crop).some((v) => v !== 0)
      const { accountId } = JWT.parseValues(token)
      try {
        if (selectedImage && validCrop) {
          await accounts.updateAvatar(
            accountId,
            selectedImage,
            crop,
            token,
            organisationId
          )
        }
        await accounts.update(token, organisationId, {
          firstName,
          familyName,
        })
        history.replace(routes.advisorOverview())
      } catch (e) {
        setLoading(false)
        setError(t("errors.error"))
      }
    }
  }

  const next = isAuthenticated ? updateUserInfo : createAccount

  const disabled =
    loading || isAuthenticated ? !userInfoValid : !accountInfoValid

  return (
    <>
      <div
        style={{
          height,
          position: "relative",
          overflow: "hidden",
          transition: "height 250ms ease-out",
        }}
      >
        {transitions.map(({ item, props: style, key, state }) => (
          <animated.div
            key={key}
            style={{
              ...style,
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <form
              ref={ref}
              onSubmit={(e) => {
                e.preventDefault()
                !disabled && next()
              }}
            >
              {isAuthenticated ? (
                <UserInfo
                  firstName={firstName}
                  familyName={familyName}
                  isCropping={isCropping}
                  onSelectImage={onSelectImage}
                  setFirstName={setFirstName}
                  setFamilyName={setFamilyName}
                  setIsCropping={setIsCropping}
                  imagePreviewUrl={previewUrl}
                  setCrop={setCrop}
                />
              ) : (
                <AccountInfo
                  email={email}
                  password={password}
                  passwordConfirmation={passwordConfirmation}
                  setPasswordConfirmation={setPasswordConfirmation}
                  setPassword={setPassword}
                  setValidPassword={setValidPassword}
                />
              )}
              <button type="submit" style={{ display: "none" }} />
            </form>
            {error && <HelpText hasError>{error}</HelpText>}
          </animated.div>
        ))}
      </div>

      <ButtonBar
        style={{ marginTop: "3rem" }}
        right={
          isAuthenticated ? (
            <Button primary onClick={next} disabled={disabled}>
              {loading ? (
                <LoadingSpinner size="small" />
              ) : (
                <>
                  <span>{t("confirm")}</span>
                  <ThumbsUp />
                </>
              )}
            </Button>
          ) : (
            <Button primary onClick={next} disabled={disabled}>
              {loading ? (
                <LoadingSpinner size="small" />
              ) : (
                <>
                  <span>{t("create")}</span>
                  <ArrowRight />
                </>
              )}
            </Button>
          )
        }
      />
    </>
  )
}

export default InvitationForm
