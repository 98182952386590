import { validated }from "~/actions/advisors"
import { advisors } from "~/api"
import Warning from "~/components/icons/Warning"
import Popout, { usePopoutState } from "~/components/Popout"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { useAuth } from "~/context/auth"
import useMountEffect from "~/hooks/useMountEffect"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import NoticeList from "../Publish/NoticeList"

const emptyValidation = {
  advice: [],
  questions: [],
  media: [],
  answers: [],
  rules: [],
  screens: [],
  lookAndFeel: [],
  catalogue: [],
}

const AdvisorValidation: React.FC<{}> = (props) => {
  const { id, productLabel } = useAdvisorContext()
  const { token, organisationId } = useAuth()
  const dispatch = useDispatch()

  const advisor: { id: string; productLabel: string } = React.useMemo(
    () => ({
      id,
      productLabel,
    }),
    [id, productLabel]
  )

  useMountEffect(() => {
    advisors
      .conversationValidation(id, token || "", organisationId)
      .then((validationResult) => {
        dispatch(validated(id, validationResult))
      })
  })

  const validations = useSelector((store: ApplicationState) => {
    const validation = store.advisorValidation[id] || emptyValidation

    return {
      advice: validation.advice,
      questions: validation.questions,
      media: validation.media,
      answers: validation.answers,
      rules: validation.rules,
      screens: validation.screens,
      lookAndFeel: [],
      catalogue: [],
    }
  })

  const warningCount = Object.values(validations).reduce(
    (acc, v) => acc + v.length,
    0
  )
  const hasWarnings = warningCount > 0

  const elementId = "conversation-validation-popout"
  const { isOpen, setOpen } = usePopoutState(elementId)
  const { t } = useTranslation()
  const theme = "light"

  return (
    <div style={{ marginTop: "2px" }}>
      <Popout
        position="bottom-start"
        theme={theme}
        toggle={
          <button
            className={isOpen ? "btn-toggle is-active" : "btn-toggle"}
            disabled={!hasWarnings}
          >
            <span className={hasWarnings ? "icon warning" : "icon muted"}>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clipRule="evenodd"
                />
              </svg> */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <path d="M236.7,188,148.8,36a24,24,0,0,0-41.6,0h0L19.3,188A23.9,23.9,0,0,0,40,224H216a23.9,23.9,0,0,0,20.7-36ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z"></path>
              </svg>
            </span>
          </button>
        }
        content={
          <div
            className={`dropdown__popout_content`}
            style={{ width: "30rem", padding: "1rem", borderRadius: "0.75rem" }}
          >
            {hasWarnings && (
              <h4 style={{ marginBottom: "0.25rem" }}>
                {warningCount} {t("general.issues", { count: warningCount })}
              </h4>
            )}
            <NoticeList
              advisor={advisor}
              result={validations}
              theme={theme}
              linkCallback={() => setOpen(false)}
            />
          </div>
        }
        id={elementId}
        isOpen={isOpen}
        setOpen={setOpen}
      />
    </div>
  )
}

export { AdvisorValidation }
