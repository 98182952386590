import { envVar, EnvVar } from "~/utils/environment"
import { Integrations } from "@sentry/tracing"
import * as Sentry from "@sentry/react"

const sentryOptions = {
  enabled: envVar(EnvVar.SENTRY_ENABLED) === true,
  dsn: envVar(EnvVar.SENTRY_DSN)?.toString() || "",
  integrations: [new Integrations.BrowserTracing()],
  environment: envVar(EnvVar.SENTRY_ENVIRONMENT)?.toString() || "",
  tracesSampleRate: parseFloat(
    envVar(EnvVar.SENTRY_SAMPLE_RATE)?.toString() || "0.0"
  ),
  ignoreErrors: ["ResizeObserver", "e.replaceData is not a function"],
}

export const setup = () => {
  Sentry.init(sentryOptions)
  Sentry.setTag("service", envVar(EnvVar.SENTRY_SERVICE)?.toString() || "")
}

export type ElmError = {
  file: string
  lineNumber: number
  message: string
  trace: string
}

export const handleElmError = (event: ElmError) => {
  const err = {
    message: `${event.message}

    ${event.trace}
    `,
    extra: {
      location: `${event.file}:${event.lineNumber}`,
      trace: event.trace,
    },
  }
  if (process.env.NODE_ENV === "development") {
    console.error(err)
  }
  Sentry.captureEvent(err)
}
